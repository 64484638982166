import * as React from "react";
import WhatsAppIcon from "./WhatsAppIcon";
import LoaderIcon from "./LoaderIcon";
import CallIcon from "./CallIcon";
import CallCircleIcon from "./CallCircleIcon";
import {IIcon} from "./types";
import WalkIcon from "./WalkIcon";
import TransportIcon from "./TransportIcon";
import KindergartenIcon from "./KindergartenIcon";
import SchoolIcon from "./SchoolIcon";
import ParkIcon from "./ParkIcon";
import FitnessIcon from "./FitnessIcon";
import RestaurantIcon from "./RestaurantIcon";

const Icon: React.FC<IIcon> = ({name, color, accentColor, className}) => {
    const IconComponent = React.useMemo(() => {
        switch (name) {
            case 'whatsapp':
                return WhatsAppIcon;
            case 'loader':
                return LoaderIcon;
            case 'call':
                return CallIcon;
            case 'call-circle':
                return CallCircleIcon;
            case 'walk':
                return WalkIcon;
            case 'transport':
                return TransportIcon;
            case 'kindergarten':
                return KindergartenIcon;
            case 'school':
                return SchoolIcon;
            case 'park':
                return ParkIcon;
            case 'fitness':
                return FitnessIcon;
            case 'restaurant':
                return RestaurantIcon;
            default:
                return null;
        }
    }, [name]);

    if (IconComponent) return (
        <IconComponent color={color} accentColor={accentColor} className={className}/>
    )

    return null;
};

export default Icon;
