import React from "react";
import {useBreakpoints} from "../../hooks";
import styled from "styled-components";

const getWidth = (breakpoints) => {
    for (let x in breakpoints) if (breakpoints[x].current) return breakpoints[x].width;
    return undefined;
};

const Wrapper = styled.div`
  display: flex;
  flex: auto;
  flex-direction: ${props => props.hasSider ? 'row' : 'column'};
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  padding: ${props => [`${props.pt || 0}px`, props.section ? '0' : `16px`, `${props.pb || 0}px`].join(' ')};
  width: ${props => (props.fluid) ? `100%` : `${getWidth(props.breakpoints)}px`};
  height: ${props => props.height ? props.height : `auto`};
`;

const Container = ({containerRef, children, ...props}) => {
    const breakpoints = useBreakpoints();

    return (
        <Wrapper ref={containerRef} breakpoints={breakpoints} {...props}>
            {children}
        </Wrapper>
    )
};

export {Container};
