import * as React from "react";
import {Thing, WithContext} from "schema-dts";

const JsonLd = <T extends Thing>(json: WithContext<T>): React.ReactElement => (
    <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{__html: JSON.stringify(json)}}
    />
);

export default JsonLd;
