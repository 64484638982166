import * as React from "react";
import lazy from '@fatso83/retry-dynamic-import/react-lazy';
import {Route, Routes} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {PropertyStack} from "./PropertyStack";
import {PropertiesPageType} from "../views/Properties/Properties";

const PropertiesPage = lazy(() => import('../views/Properties/Properties')) as unknown as PropertiesPageType;

const ExclusivesStack: React.FC = () => (
    <Routes>
        <Route
            path='/'
            element={
                <PropertiesPage
                    initial={{
                        details__offer_status: 'secondary_offer',
                        details__offer_type: 'exclusive',
                    }}
                    header={{
                        title: 'properties.core.exclusives.main.header.title',
                        description: 'properties.core.exclusives.main.header.description',
                    }}
                    cardToPath={'agents.exclusives.property.absolute'}
                    metaPath={'agents.exclusives'}
                    urlPath={'agents.exclusives.absolute'}
                    objective={'exclusives'}
                />
            }
        />
        <Route
            path=':id/*'
            element={
                <PropertyStack objective="exclusives"/>
            }
        />
    </Routes>
);

const AgentsStack: React.FC = () => {
    const {t} = useTranslation('urls');

    return (
        <Routes>
            <Route
                path={t('agents.exclusives.client')}
                element={<ExclusivesStack/>}
            />
        </Routes>
    );
};

export {AgentsStack};
