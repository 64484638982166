import * as React from "react";
import Generate from "../Generate";
import Validate from "../Validate";
import {TSetForm} from "../../types";

export interface IForm {
    titleClassName?: string;
    descriptionClassName?: string;
    generateTitle?: string | null;
    generateDescription?: string | null;
    generateButtonTitle?: string | null;
    validateTitle?: string | null;
    validateDescription?: string | null;
    formContainerClassName?: string;
    generateHelpText?: string;
    validateHelpText?: string;
    onValidateSuccess?: () => void;
    showPrivacyDisclaimer?: boolean;
}

const Form: React.FC<IForm> = (
    {
        titleClassName,
        descriptionClassName,
        generateTitle,
        generateDescription,
        generateButtonTitle,
        validateTitle,
        validateDescription,
        formContainerClassName,
        generateHelpText,
        validateHelpText,
        onValidateSuccess,
        showPrivacyDisclaimer,
    }
) => {
    const [state, setState] = React.useState<{
        form: 'generate' | 'validate';
        phone?: string;
    }>({
        form: 'generate',
    });

    const setForm: TSetForm = (form, phone) => {
        setState((s) => {
            let d = {...s, form};
            if (phone) d = {...d, phone};
            return d;
        });
    };

    return (
        <>
            {state.form === 'generate' && <Generate
                setForm={setForm}
                generateTitle={generateTitle}
                generateDescription={generateDescription}
                titleClassName={titleClassName}
                descriptionClassName={descriptionClassName}
                formContainerClassName={formContainerClassName}
                generateHelpText={generateHelpText}
                generateButtonTitle={generateButtonTitle}
                showPrivacyDisclaimer={showPrivacyDisclaimer}
            />}
            {state.form === 'validate' && <Validate
                phone={state.phone}
                validateTitle={validateTitle}
                validateDescription={validateDescription}
                titleClassName={titleClassName}
                descriptionClassName={descriptionClassName}
                formContainerClassName={formContainerClassName}
                validateHelpText={validateHelpText}
                onValidateSuccess={onValidateSuccess}
            />}
        </>
    );
};

export default Form;
