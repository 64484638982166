import * as React from "react";
import {useAccount, useRouter} from "../hooks";
import {Navigate, Outlet} from "react-router-dom";
import {useTranslation} from "react-i18next";

const AccountProtected: React.FC = () => {
    const {user} = useAccount();
    const router = useRouter();
    const {t} = useTranslation(['url']);

    if (!user.loggedIn) {
        return <Navigate
            to={t('account.login.absolute', {ns: 'url'})}
            replace={true}
            state={{from: router.location}}
        />;
    }

    return <Outlet/>;
};

const StaffProtected: React.FC = () => {
    const {user} = useAccount();
    const router = useRouter();
    const {t} = useTranslation(['url']);

    if (!user.loggedIn) {
        return <Navigate
            to={t('account.login.absolute', {ns: 'url'})}
            replace={true}
            state={{from: router.location}}
        />;
    }

    if ('isStaff' in user && user.isStaff) {
        return <Outlet/>;
    }

    return <Navigate
        to={'/'}
        replace={true}
        state={{from: router.location}}
    />;
};

export {AccountProtected, StaffProtected};
