import * as React from "react";
import {NumberFormatValues, NumericFormat} from "react-number-format";
import {getNumericValue} from "../../../../helpers";
import InputWrapper from "../Common/InputWrapper";
import InputLabel from "../Common/InputLabel";
import InputContainer from "../Common/InputContainer";
import InputField from "../Common/InputField";
import InputError from "../Common/InputError";
import {IInput, TOnChange} from "../../../../types/fields";

interface InputProps extends IInput, Omit<React.InputHTMLAttributes<HTMLInputElement>, 'name' | 'type' | 'value'> {
    onChange: TOnChange;
    ref?: React.Ref<HTMLInputElement>;
}

const Input: React.FC<InputProps> = (
    {
        label,
        name,
        value = '',
        type = 'text',
        placeholder,
        valueType = 'text',
        prefixComponent,
        suffixComponent,
        error,
        numericThousandSeparator = ' ',
        inputContainerClassName,
        inputErrorClassName,
        inputClassName,
        onChange,
        ref,
        ...rest
    }
) => {
    const numericDecimalScale = React.useMemo(
        () => {
            if (valueType === 'number') return 0;
            if (valueType === 'decimal') return 2;
            return undefined;
        },
        [valueType]
    );

    const onNumericChange = ({values, onChange}: { values: NumberFormatValues, onChange: TOnChange }) => {
        onChange(getNumericValue(values.floatValue));
    };

    return (
        <InputWrapper>
            {label && (
                <InputLabel htmlFor={name}>
                    {label}
                </InputLabel>
            )}
            <InputContainer className={inputContainerClassName} error={error}>
                {prefixComponent && prefixComponent}
                {['number', 'decimal'].includes(valueType)
                    ? <NumericFormat
                        name={name}
                        defaultValue={value}
                        onValueChange={(values) => onNumericChange({values, onChange})}
                        allowNegative={false}
                        decimalScale={numericDecimalScale}
                        thousandSeparator={numericThousandSeparator}
                        placeholder={placeholder}
                        className={inputClassName}
                        customInput={InputField}
                    />
                    : <InputField
                        ref={ref}
                        type={type}
                        value={value ? value : ''}
                        onChange={onChange}
                        placeholder={placeholder}
                        className={inputClassName}
                        {...rest}
                    />
                }
                {suffixComponent && suffixComponent}
            </InputContainer>
            {error && <InputError inputErrorClassName={inputErrorClassName}>{error.message}</InputError>}
        </InputWrapper>
    );
};

export default Input;
