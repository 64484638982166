import React from "react";
import styled from "styled-components";

const Container = styled.div`
  background-color: ${({color, theme}) => color ? color : theme.colors.gray[3]};
  display: block;
  clear: both;
  width: 100%;
  min-width: 100%;
  height: 1px;
`;

const Wrapper = styled.div`
  padding-top: ${({top}) => top ? `${top}px` : 0};
  padding-bottom: ${({bottom}) => bottom ? `${bottom}px` : 0};
  padding-left: ${({left}) => left ? `${left}px` : 0};
  padding-right: ${({right}) => right ? `${right}px` : 0};
`;

const Divider = ({top, bottom, left, right, color}) => {
    return (
        <Wrapper
            top={top}
            bottom={bottom}
            left={left}
            right={right}
        >
            <Container color={color}/>
        </Wrapper>
    )
};

export {Divider};
