import {createClient, NormalizedCacheObject} from "@sputnikestate/graph";
import {Cookies} from "react-cookie";
import {generateAnonymousId} from "../helpers/session";
import {getLang} from "../locale/helpers";

const getHeaders = async () => {
    const headers: { [key: string]: string } = {
        'Accept-Language': getLang(),
    };

    const cookies = new Cookies();

    let sputnikAnonymousId = cookies.get('sputnik_anonymous_id');
    if (!sputnikAnonymousId) {
        sputnikAnonymousId = generateAnonymousId();
        cookies.set('sputnik_anonymous_id', sputnikAnonymousId, {
            path: '/',
            maxAge: 31556952,
            secure: process.env.NODE_ENV === 'production',
            sameSite: 'lax',
        });
    }

    headers['Sputnik-Anonymous-ID'] = sputnikAnonymousId;

    const token = cookies.get('sputnik_user_token');
    if (token) headers['Authorization'] = `Token ${token}`;

    return headers;
};

const fetcher = async (input: RequestInfo | URL, init?: RequestInit) => {
    const headers = await getHeaders();

    if (init) init.headers = {
        ...init.headers,
        ...headers,
    };

    return fetch(input, init);
};

const wsConnectionParams = async () => {
    return await getHeaders();
};

const getCache = () => {
    const cache: NormalizedCacheObject = window.__GRAPH_STATE__;

    if (cache) {
        window.__GRAPH_STATE__ = undefined;
    }

    const script = document.getElementById('graph');
    if (script) script.remove();

    if (cache) return cache;

    return null;
};

const client = createClient({
    http: process.env.REACT_APP_GRAPHQL_HOST || 'https://sputnik.estate/graphql',
    ws: process.env.REACT_APP_GRAPHQL_WS_HOST || 'wss://sputnik.estate/graphql',
    cache: getCache(),
    fetcher,
    wsConnectionParams,
});

export {client};
