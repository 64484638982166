import * as React from "react";
import {Menu, MenuButton, MenuItem, MenuItems, Transition} from "@headlessui/react";
import {twMerge} from "tailwind-merge";
import {useTranslation} from "react-i18next";
import {ArrowsUpDownIcon, CheckIcon} from "@heroicons/react/20/solid";
import ScrollLock from "react-scrolllock";
import {useBreakpoints} from "../../hooks";
import {useSwipeable} from "react-swipeable";
import {FiltersType, SortOptionsType, SortOptionType, UpdateFilterType} from "../../types";
import Light from "../primitives/Buttons/Light";

interface ISortBase {
    name: string;
    filters?: FiltersType;
    updateFilter: UpdateFilterType;
}

interface ISortFilter extends ISortBase {
    options: SortOptionsType;
}

interface IContent extends ISortFilter {
    open: boolean;
    close: () => void;
}

interface IItem extends ISortBase {
    item: SortOptionType;
}

interface IButton {
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const Item: React.FC<IItem> = ({name, item, filters, updateFilter}) => {
    const {t} = useTranslation(['common']);

    const checked = React.useMemo(
        () => {
            if (filters) return item.value === filters[name];
            return false;
        },
        [filters]
    );

    const onClickHandler = (value: string) => {
        if (filters && item.value === filters[name]) {
            updateFilter(name, undefined);
        } else {
            updateFilter(name, value);
        }
    };

    return (
        <MenuItem>
            {({focus}) => (
                <button
                    onClick={() => onClickHandler(item.value)}
                    className={twMerge(
                        'flex items-center gap-3 md:gap-2 h-12 md:h-10 w-full text-left pl-2 pr-4 rounded-lg text-base md:text-sm font-medium focus:outline-none',
                        focus && 'bg-blue-25 text-blue-500'
                    )}
                >
                    <div
                        className={twMerge(
                            'flex items-center justify-center shrink-0 w-6 h-6 md:w-5 md:h-5 rounded-full mt-0.5 bg-white border border-cultured-150',
                            checked && 'bg-blue-500 border-none',
                            focus && !checked && 'bg-blue-25 border-blue-50',
                        )}>
                        {checked && <CheckIcon className="h-4 w-4 text-white"/>}
                    </div>
                    <div className="mt-0.5">{t(item.label)}</div>
                </button>
            )}
        </MenuItem>
    );
};

const Content: React.FC<IContent> = ({open, close, name, options, filters, updateFilter}) => {
    const {t} = useTranslation(['common']);
    const {isMobile} = useBreakpoints();
    const handlers = useSwipeable({
        onSwipedDown: () => close(),
    });

    const Button: React.FC<IButton> = ({onClick}) => (
        <Light
            className="grow-0 shrink-0 w-12 sm:h-12"
            titleClassName={'hidden lg:block'}
            onClick={onClick}
            prefix={(
                <ArrowsUpDownIcon className="h-5 w-5 text-blue-500"/>
            )}
        />
    );

    return (
        <>
            <div>
                <MenuButton as={Button}/>
            </div>
            <Transition
                as={React.Fragment}
                enter="md:transition ease-out duration-300 md:duration-100"
                enterFrom="backdrop-blur-none md:transform opacity-0 md:scale-95"
                enterTo="backdrop-blur md:backdrop-blur-none md:transform opacity-100 md:scale-100"
                leave="md:transition ease-in duration-200 md:duration-75"
                leaveFrom="backdrop-blur md:backdrop-blur-none md:transform opacity-100 md:scale-100"
                leaveTo="backdrop-blur-none md:transform opacity-0 md:scale-95"
            >
                <MenuItems
                    className="fixed md:absolute top-0 md:top-auto bottom-0 md:bottom-auto left-0 right-0 md:right-auto z-10 mt-0 md:mt-2 w-full md:w-72 md:origin-top-right"
                >
                    <div
                        onClick={close}
                        className="md:hidden absolute inset-0 bg-black bg-opacity-40 transition-opacity backdrop-blur opacity-100"
                    />
                    <Transition
                        as={React.Fragment}
                        enter="md:transition ease-out duration-300 md:duration-100"
                        enterFrom="md:transform opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                        enterTo="md:transform opacity-100 translate-y-0 md:scale-100"
                        leave="md:transition ease-in duration-200 md:duration-75"
                        leaveFrom="md:transform opacity-100 translate-y-0 md:scale-100"
                        leaveTo="md:transform opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                    >
                        <div
                            className="absolute md:relative bottom-0 md:bottom-auto inset-x-0 md:inset-x-auto z-1 bg-white px-2 pb-5 md:p-2 rounded-t-4xl md:rounded-lg md:bg-white md:shadow-lg"
                        >
                            <div {...handlers} className="flex flex-col items-center md:hidden">
                                <div className="mt-3 h-1 w-9 bg-cultured-150 rounded-full"/>
                                <div className="font-black text-xl mt-3.5 mb-2.5">
                                    {t('lists.sort')}
                                </div>
                            </div>
                            {options.map((item) => (
                                <Item
                                    key={item.value}
                                    name={name}
                                    item={item}
                                    filters={filters}
                                    updateFilter={updateFilter}
                                />
                            ))}
                        </div>
                    </Transition>
                    <ScrollLock isActive={open && isMobile}/>
                </MenuItems>
            </Transition>
        </>
    );
};

const SortFilter: React.FC<ISortFilter> = ({name, options, filters, updateFilter}) => (
    <div className="md:relative">
        <Menu as="div" className="inline-block text-left">
            {({open, close}) => <Content
                open={open}
                close={close}
                name={name}
                options={options}
                filters={filters}
                updateFilter={updateFilter}
            />}
        </Menu>
    </div>
);

export default SortFilter;
