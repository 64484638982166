import * as React from "react";
import {useMediaQuery} from "react-responsive";

type Props = {
    children: React.ReactNode
};

type UseBreakpointsContext = ReturnType<typeof useProvideBreakpoints>;

const breakpointsContext = React.createContext<UseBreakpointsContext>({} as UseBreakpointsContext);

const BreakpointsProvider: React.FC<Props> = ({children}) => {
    const breakpoints = useProvideBreakpoints();

    return (
        <breakpointsContext.Provider value={breakpoints}>
            {children}
        </breakpointsContext.Provider>
    );
};

const useBreakpoints = () => {
    return React.useContext(breakpointsContext);
};

const useProvideBreakpoints = () => {
    const isClient = typeof window !== 'undefined';

    const isMobile = useMediaQuery({maxWidth: 767})
    const isTablet = useMediaQuery({minWidth: 768, maxWidth: 1199})
    const isDesktop = useMediaQuery({minWidth: 1200})

    const xsCurrent = useMediaQuery({maxWidth: 575});
    const xsViewport = useMediaQuery({maxWidth: 576});

    const smCurrent = useMediaQuery({minWidth: 576, maxWidth: 767});
    const smViewport = useMediaQuery({minWidth: 576});

    const mdCurrent = useMediaQuery({minWidth: 768, maxWidth: 991});
    const mdViewport = useMediaQuery({minWidth: 768});

    const lgCurrent = useMediaQuery({minWidth: 992, maxWidth: 1199});
    const lgViewport = useMediaQuery({minWidth: 992});

    const xlCurrent = useMediaQuery({minWidth: 1200, maxWidth: 1399});
    const xlViewport = useMediaQuery({minWidth: 1200});

    const xxlCurrent = useMediaQuery({minWidth: 1400});
    const xxlViewport = useMediaQuery({minWidth: 1400});

    return {
        isMobile,
        isTablet,
        isDesktop,
        xs: {
            width: 480,
            current: isClient ? xsCurrent : isMobile,
            viewport: isClient ? xsViewport : isMobile,
        },
        sm: {
            width: 540,
            current: isClient ? smCurrent : false,
            viewport: isClient ? smViewport : (isMobile || isTablet || isDesktop),
        },
        md: {
            width: 720,
            current: isClient ? mdCurrent : isTablet,
            viewport: isClient ? mdViewport : (isTablet || isDesktop),
        },
        lg: {
            width: 960,
            current: isClient ? lgCurrent : false,
            viewport: isClient ? lgViewport : (isTablet || isDesktop),
        },
        xl: {
            width: 1140,
            current: isClient ? xlCurrent : isDesktop,
            viewport: isClient ? xlViewport : isDesktop,
        },
        xxl: {
            width: 1320,
            current: isClient ? xxlCurrent : false,
            viewport: isClient ? xxlViewport : false,
        }
    }
};

export {useBreakpoints, BreakpointsProvider};
