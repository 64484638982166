import React from "react";
import styled from "styled-components";

const getColors = (kind, type, theme) => {
    switch (type) {
        case 'primary':
            return {
                color: (kind === 'a') ? theme.colors.blue.primary : theme.colors.white.primary,
                background: (kind === 'a') ? 'transparent' : theme.colors.blue.primary,
                border: (kind === 'a') ? 'transparent' : theme.colors.blue.primary
            };
        case 'secondary':
            return {
                color: (kind === 'a') ? theme.textColor : theme.colors.blue.primary,
                background: (kind === 'a') ? 'transparent' : theme.colors.aliceBlue[1],
                border: (kind === 'a') ? 'transparent' : theme.colors.aliceBlue[1]
            };
        case 'tertiary':
            return {
                color: theme.colors.blue.primary,
                background: 'transparent',
                border: 'transparent'
            };
        case 'inverse':
            return {
                color: theme.colors.white.primary,
                background: (kind === 'a') ? 'transparent' : theme.colors.grey.primary,
                border: (kind === 'a') ? 'transparent' : theme.colors.grey.primary
            };
        case 'default':
            return {
                color: (kind === 'a') ? theme.textColor : theme.colors.blue.primary,
                background: (kind === 'a') ? 'transparent' : theme.backgroundColor,
                border: (kind === 'a') ? 'transparent' : theme.backgroundColor
            };
        default:
            return {
                color: (kind === 'a') ? theme.colors.blue.primary : theme.colors.white.primary,
                background: (kind === 'a') ? 'transparent' : theme.colors.blue.primary,
                border: (kind === 'a') ? 'transparent' : theme.colors.blue.primary
            };
    }
};

const ButtonWrapper = styled.button`
    position: relative;
    height: ${({size}) => {
        if (size === 's') return '42px';
        return '48px';
    }};
    width: ${({width}) => width || '100%'};
    font-size: 1em;
    color: ${({kind, type, theme}) => getColors(kind, type, theme).color};
    background-color: ${({kind, type, theme}) => getColors(kind, type, theme).background};
    border-style: solid;
    border-width: 1px;
    border-color: ${({kind, type, theme}) => getColors(kind, type, theme).border};
    border-radius: ${({rounded}) => {
        if (rounded) return '50px';
        return '4px';
    }};
    box-shadow: ${({type}) => ['tertiary', 'secondary', 'default'].includes(type) ? 'none' : '0 1px 4px 0 rgba(0, 0, 0, 0.24)'};
    cursor: pointer;
    margin-top: ${({mt}) => mt ? `${mt}px` : 0};
    margin-bottom: ${({mb}) => mb ? `${mb}px` : 0};
    margin-left: ${({ml}) => ml ? `${ml}px` : 0};
    margin-right: ${({mr}) => mr ? `${mr}px` : 0};

    &:hover {
        transition: all .5s ease;
        opacity: ${({type}) => ['default'].includes(type) ? '1.0' : '0.8'};
        border-radius: ${({type, rounded}) => {
            if (type === 'default') {
                if (rounded) return '4px';
                return '50px';
            }
            return 'inherit';
        }};
    }

    &:disabled {
        transition: all .5s ease;
        opacity: 0.6;
        cursor: initial;
    }

    & svg {
        position: relative;
        top: 0;
        margin-left: ${({child}) => child ? '7px' : 0};
    }
`;

const LinkWrapper = styled.a`
    display: ${({mt, mb}) => mt || mb ? 'block' : 'inherit'};
    cursor: pointer;
    touch-action: manipulation;
    margin-top: ${({mt}) => mt ? `${mt}px` : 0};
    margin-bottom: ${({mb}) => mb ? `${mb}px` : 0};
    margin-left: ${({ml}) => ml ? `${ml}px` : 0};
    margin-right: ${({mr}) => mr ? `${mr}px` : 0};
    white-space: nowrap;

    &:hover {
        transition: all .5s ease;
        opacity: 0.8;
    }
;

    & svg {
        margin-left: 7px;
    }
`;

const Title = styled.span`
    color: ${({titleColor, kind, type, theme}) => titleColor ? titleColor : getColors(kind, type, theme).color};
    font-size: ${({size, theme}) => (size) ? theme.fontSize[size].size : theme.fontSize.m.size};
    font-weight: ${({titleWeight}) => titleWeight ? titleWeight : 600};
`;

const Button = ({
                    children,
                    titleColor,
                    titleWeight,
                    icon,
                    iconSize,
                    iconColor,
                    kind,
                    disabled,
                    type,
                    size,
                    rounded = false,
                    ...props
                }) => {
    return React.createElement(
        (kind === 'a') ? LinkWrapper : ButtonWrapper,
        {
            disabled: disabled,
            kind: kind,
            child: children,
            type: type,
            size: size,
            rounded: rounded,
            ...props
        },
        <>
            {children && (
                <Title
                    kind={kind}
                    type={type}
                    size={size}
                    titleColor={titleColor}
                    titleWeight={titleWeight}
                >
                    {children}
                </Title>
            )}
        </>
    )
};

export {Button};
