import {merge} from "lodash";
import {customAlphabet} from "nanoid";
import {alphanumeric} from "nanoid-dictionary";

export const formatBytes = (bytes, decimals) => {
    if (bytes === 0) return '0 Bytes';
    let k = 1024,
        dm = decimals || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const strToBool = value => {
    switch (value) {
        case 'true':
        case 'yes':
        case '1':
            return true;
        case 'false':
        case 'no':
        case '0':
            return false;
        default:
            return null;
    }
};

export const contains = (target, pattern) => {
    if (pattern) {
        let value = 0;
        pattern.forEach((item) => {
            value = value + target.includes(item);
        });
        return (value === 1)
    }
    return false;
};

export const callClickHandler = (phone) => {
    window.location.href = `tel:${phone}`;
};

export const waClickHandler = (phone, text) => {
    let url = `whatsapp://send?phone=${phone}`;

    if (text) url = url + `&text=${text}`;

    window.location.href = url;
};

export const getLocationWithUTMs = ({navigationLocation, searchParams}) => {
    let query = {};
    let utm = {};

    for (let param of searchParams) {
        if (!['page'].includes(param[0])) {
            utm[param[0]] = param[1];
        }
    }

    let path = navigationLocation.split('?');

    if (path[1]) {
        query = new URLSearchParams(path[1]);
    }

    query = merge(query, utm);

    let queryString = new URLSearchParams(query).toString();

    if (queryString) {
        navigationLocation = path[0] + '?' + queryString;
    }

    return navigationLocation;
};

export const nanoid = () => {
    const id = customAlphabet(alphanumeric, 15);
    return id();
};
