import React from "react";
import styled from "styled-components";

const getAlignItems = (vertical) => {
    switch (vertical) {
        case 'top':
            return 'flex-start';
        case 'middle':
            return 'center';
        case 'bottom':
            return 'flex-end';
        default:
            return 'flex-start';
    }
};

const Wrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    justify-content: ${props => props.justify || 'start'};
    flex-wrap: wrap;
    align-items: ${props => getAlignItems(props.vertical)};
    padding-top: ${props => props.pt || 0}px;
    padding-bottom: ${props => props.pb || 0}px;
    margin-left: -8px;
    margin-right: -8px;
`;

const Row = ({children, ...props}) => {
    return (
        <Wrapper {...props}>
            {children}
        </Wrapper>
    )
};

export {Row};
