export const Locale = {
    EN: 'en',
    RU: 'ru',
};

export const localeNames = {
    [Locale.EN]: 'English',
    [Locale.RU]: 'Русский',
};

export const defaultLocale = Locale.EN;

export const supportedLanguages = [Locale.EN, Locale.RU];
