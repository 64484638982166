import {BackendModule} from "i18next";

const LazyImportPlugin: BackendModule = {
    type: 'backend',
    init: () => {
    },
    read: (language, namespace, callback) => {
        import(/* webpackChunkName: "i18n/[request]" */ `./translations/${language}/${namespace}.json`).then(
            (obj) => {
                callback(null, obj);
            }
        );
    },
    save: () => {
    },
    create: () => {
    },
};

export default LazyImportPlugin;
