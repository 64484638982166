const preparePath = (path: string) => {
    path = path
        .replace(/\/+/g, '/') // Replace multiple slash (//) with single ones
        .replace(/^\//, '') // Remove extra leading slash
        .replace(/\?.*$/, '') // Remove query params
        .replace(/\/+$/, ''); // Remove trailing slash

    // Make sure there is a leading slash
    path = path.startsWith('/') ? path : `/${path}`;

    return path;
};

export default preparePath;
