import * as React from "react";
import {IIconSVGProps} from "./types";

const CallCircleIcon: React.FC<IIconSVGProps> = ({color, accentColor, className, ref}) => (
    <svg ref={ref} className={className} viewBox="0 0 560 560" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M280,0 C433.92,0 560,126.08 560,280 C560,433.92 433.92,560 280,560 C126.08,560 0,433.92 0,280 C0,126.08 126.08,0 280,0 Z"
            fill={color}
        />
        <path
            d="M130.184441,188.607284 C124.094455,283.984139 270.199216,423.997674 363.542399,430.094972 C377.74588,430.094972 389.920647,428.066629 400.067938,415.89149 C406.157924,407.773162 414.27142,401.688009 422.389996,393.569432 C432.536668,383.422761 432.536668,371.247374 422.389996,361.101322 C410.214982,346.897841 396.011748,332.689402 381.803309,320.514636 C371.656638,310.367964 361.509966,310.367964 351.363914,320.514636 C343.245585,328.632964 333.099285,336.746832 324.985666,344.864169 C322.957323,348.920855 318.89568,348.920855 314.838994,346.892512 C276.285979,324.570454 229.609431,286.017439 213.377235,243.402037 C211.348892,239.345352 211.348892,237.312052 215.405577,233.255366 C223.523906,227.16538 231.637774,217.02317 239.755111,208.905832 C249.901783,198.759161 249.901783,186.583774 239.755111,176.437722 C225.551629,164.262708 213.376863,150.059474 199.168424,137.884707 C189.021752,127.738036 176.846366,127.738036 166.700314,135.856364 C158.581985,146.003036 150.468118,154.120993 142.350781,162.234613 C134.232452,168.324598 130.175766,178.466809 130.175766,188.612861 L130.184441,188.607284 Z"
            fill={accentColor}
        />
    </svg>
);

export default CallCircleIcon;
