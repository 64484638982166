import * as React from "react";

interface InputLabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
    children: string;
    ref?: React.Ref<HTMLLabelElement>;
}

const InputLabel: React.FC<InputLabelProps> = ({children, ref}) => (
    <label ref={ref} className="text-xs text-gray-500 mb-2 leading-3">
        {children}
    </label>
);

export default InputLabel;
