import * as React from "react";
import {twMerge} from "tailwind-merge";
import {FieldError} from "react-hook-form";

interface InputContainerProps {
    error?: FieldError;
    className?: string;
    children?: React.ReactNode;
}

const InputContainer: React.FC<InputContainerProps> = ({error, className, children}) => (
    <div
        className={twMerge(
            'flex flex-row h-12 sm:h-14 bg-cultured-100 rounded-lg border border-cultured-100 hover:bg-blue-25 hover:border-blue-25 focus-within:bg-blue-25 focus-within:border-blue-25 transition-colors',
            error && 'bg-red-100 border-red-100',
            className
        )}
    >
        {children}
    </div>
);

export default InputContainer;
