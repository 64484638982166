import * as React from "react";
import {Toaster as ToasterBase} from "react-hot-toast";

const Toaster: React.FC = () => (
    <ToasterBase
        toastOptions={{
            className: 'text-sm'
        }}
    />
);

export default Toaster;
