import * as React from "react";

interface InputWrapperProps {
    children: React.ReactNode;
}

const InputWrapper: React.FC<InputWrapperProps> = ({children}) => (
    <div className="flex flex-col w-full h-full">
        {children}
    </div>
);

export default InputWrapper;
