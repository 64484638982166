import * as React from "react";
import {Control, useController} from "react-hook-form";
import InputBase from "../../../primitives/Fields/Input";
import {IInput} from "../../../../types/fields";

interface IControlledInput extends IInput {
    control: Control<any>;
}

const Input: React.FC<IControlledInput> = (
    {
        control,
        label,
        error,
        prefixComponent,
        suffixComponent,
        name,
        value,
        type,
        valueType,
        placeholder,
        numericThousandSeparator,
        inputContainerClassName,
        inputClassName,
        inputErrorClassName,
    }
) => {
    const defaultValue = React.useMemo(
        () => {
            if (valueType) {
                if (['number', 'decimal'].includes(valueType) && !value) return null;
                if (['text'].includes(valueType) && !value) return '';
            }
            return value;
        },
        []
    );

    const {field} = useController({name, control, defaultValue});

    return (
        <InputBase
            label={label}
            type={type}
            valueType={valueType}
            placeholder={placeholder}
            prefixComponent={prefixComponent}
            suffixComponent={suffixComponent}
            error={error}
            numericThousandSeparator={numericThousandSeparator}
            inputContainerClassName={inputContainerClassName}
            inputClassName={inputClassName}
            inputErrorClassName={inputErrorClassName}
            {...field}
        />
    );
};

export default Input;
