import React from "react";
import {useBreakpoints} from "../../hooks";
import styled from "styled-components";

const getFlexBasis = (props) => {
    let value = undefined;

    ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'].forEach(el => {
        if (props[el] && props.breakpoints[el].viewport) {
            value = `${props[el] / 24 * 100}%`
        }
    });

    return value
};

const Wrapper = styled.div`
    box-sizing: border-box;
    flex: 0 0 auto;
    order: ${props => props.order || 1};
    margin-top: ${props => props.mt || 0}px;
    margin-bottom: ${props => props.mb || 0}px;
    padding: ${props => [`${props.pt || 0}px`, `8px`, `${props.pb || 0}px`, `8px`].join(' ')};
    flex-basis: ${props => getFlexBasis(props)};
    max-width: ${props => getFlexBasis(props)};
`;

const Col = ({children, ...props}) => {
    const breakpoints = useBreakpoints();

    return (
        <Wrapper breakpoints={breakpoints} {...props}>
            {children}
        </Wrapper>
    )
};

export {Col};
