import * as React from "react";
import {DialogPanel, TransitionChild} from "@headlessui/react";
import {XMarkIcon} from "@heroicons/react/24/outline";
import {Form} from "../../../../../Form";
import Input from "../../../../Fields/Input";
import PrimaryButton from "../../../../../primitives/Buttons/Primary";
import {useTranslation} from "react-i18next";
import {
    FragmentType,
    getFragmentData,
    LeadFragment,
    LeadObjective,
    LeadType,
    useLeadCreate
} from "@sputnikestate/graph";
import {useAnalytics} from "../../../../../../hooks";
import {useCookies} from "react-cookie";
import {useCallActionStore} from "@sputnikestate/store";
import toast from "react-hot-toast";

interface IRequest {
    cancelButtonRef: any;
}

const Request: React.FC<IRequest> = ({cancelButtonRef}) => {
    const {t} = useTranslation(['general']);
    const analytics = useAnalytics();
    const [cookies] = useCookies(['yclid']);

    const lead = useCallActionStore((state) => state.lead);

    const handleCallModal = useCallActionStore((state) => state.handleCallModal);

    const onSuccess = (fragmentData: FragmentType<typeof LeadFragment>) => {
        const data = getFragmentData(LeadFragment, fragmentData);

        handleCallModal();
        toast.success(t('overlays.callRequest.successMessage', {ns: 'general'}));
        analytics.track('Lead Created', {
            leadId: Number(data.id),
            type: lead?.issue.type || LeadType.InfoRequest,
            issueType: lead?.issue.type || LeadType.InfoRequest,
            issueObjective: lead?.issue.objective || LeadObjective.Property,
            ...lead?.analytics,
        });
    };

    const {
        control,
        onSubmit,
        setValue,
        formState: {isSubmitting, errors}
    } = useLeadCreate({
        onSuccess
    });

    React.useEffect(
        () => {
            if (lead?.issue) {
                setValue('type', lead.issue.type);
                setValue('issue', lead.issue);
            } else {
                setValue('type', LeadType.InfoRequest);
                setValue('issue', {
                    type: LeadType.InfoRequest,
                    objective: LeadObjective.Property,
                });
            }
        },
        [lead?.issue]
    );

    React.useEffect(
        () => {
            analytics.ym('getClientID', (yandexClientId: any) => {
                setValue('details.yandex.yandexClientId', yandexClientId);
            });
        },
        [analytics]
    );

    React.useEffect(
        () => {
            if (cookies.yclid) setValue('details.yandex.yandexYclid', String(cookies.yclid));
        },
        [cookies]
    );

    React.useEffect(
        () => {
            if (lead?.analytics) {
                setValue('details.source', lead.analytics);
            }
        },
        [lead?.analytics]
    );

    return (
        <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end lg:items-center justify-center p-2 xl:p-4">
                <TransitionChild
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 lg:translate-y-0 lg:scale-95"
                    enterTo="opacity-100 translate-y-0 lg:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 lg:scale-100"
                    leaveTo="opacity-0 translate-y-4 lg:translate-y-0 lg:scale-95"
                >
                    <DialogPanel
                        className="relative transform rounded-2xl bg-white text-left shadow-xl transition-all w-full lg:w-9/12 xl:w-3/6"
                    >
                        <div className="relative flex flex-col pt-4 xl:pt-6 pb-4 lg:pb-28 2xl:pb-32 h-full">
                            <div className="flex justify-end items-start px-4 xl:px-6 pt-0.5 z-10">
                                <button
                                    type="button"
                                    className="flex items-center justify-center h-10 w-10 rounded-full border border-transparent bg-cultured-100 hover:bg-cultured-150 focus:outline-none"
                                    onClick={() => handleCallModal()}
                                    ref={cancelButtonRef}
                                >
                                    <XMarkIcon className="h-6 w-6 text-blue-500"/>
                                </button>
                            </div>
                            <div className="flex flex-col justify-center mt-8 lg:mt-12 2xl:mt-14 h-full">
                                <div
                                    className="flex flex-col items-center justify-center xl:container xl:mx-auto px-4 sm:px-6 min-h-[inherit]"
                                >
                                    <div className="relative w-full md:w-3/4 mx-auto">
                                        <h1
                                            className="text-slate-900 font-black text-4xl sm:text-5xl tracking-tight text-center"
                                        >
                                            {t('overlays.callRequest.title', {ns: 'general'})}
                                        </h1>
                                        <div
                                            className="text-center font-medium text-slate-600 text-base sm:text-xl mt-4 md:mt-6"
                                        >
                                            {t('overlays.callRequest.description', {ns: 'general'})}
                                        </div>
                                        <div className="mt-10">
                                            <Form onSubmit={onSubmit}>
                                                <Input
                                                    control={control}
                                                    name="contact.phone"
                                                    valueType="text"
                                                    error={errors.contact?.phone}
                                                    inputContainerClassName="h-16 sm:h-16"
                                                    inputClassName="text-2xl"
                                                />
                                                <PrimaryButton
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                    loading={isSubmitting}
                                                    title={t('overlays.callRequest.buttonTitle', {ns: 'general'})}
                                                    className="h-14 lg:h-16 lg:text-lg mt-6"
                                                />
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogPanel>
                </TransitionChild>
            </div>
        </div>
    );
};

export default Request;
