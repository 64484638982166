import {EventProperties} from "./types";

export {rudderInitialize} from "./rudder";

export const eventsAllowList: (keyof EventProperties)[] = [
    'Application Opened',
    'Properties Viewed',
    'Developments Viewed',
    'Development Viewed',
    'Development Offer Clicked',
    'Development Latest Clicked',
    'Development Discount Clicked',
    'Development Nearby Clicked',
    'Development Schedule Tour Opened',
    'Development Subscription Create Opened',
    'Development Subscription Created',
    'Development File Download Sign In',
    'Development File Downloaded',
    'Development Properties Viewed',
    'Communities Viewed',
    'Community Viewed',
    'Community Properties Viewed',
    'Property Viewed',
    'Call Agent Clicked',
    'Whatsapp Agent Clicked',
    'Lead Created',
    'Smart Selection Viewed',
    'Smart Selection Step',
    'Smart Selection Initiated',
    'Smart Selection Completed',
    'Login OTP Generated',
    'Login OTP Validated',
    'CLS',
    'FCP',
    'FID',
    'INP',
    'LCP',
    'TTFB',
];

export type EventName = keyof EventProperties;

export type {EventProperties};
