import * as React from "react";
import lazy from '@fatso83/retry-dynamic-import/react-lazy';
import {Route, Routes} from "react-router-dom";
import {PropertiesStack} from "./PropertiesStack";
import {DevelopmentsStack} from "./DevelopmentsStack";
import {AgentsStack} from "./AgentsStack";
import {useTranslation} from "react-i18next";
import {Placeholder} from "../components";
import {EstatesStack} from "./EstatesStack";
import DynamicRoute from "./DynamicRoute";

const AccountStack = lazy(() => import('./AccountStack'));
const HomePage = lazy(() => import('../views/home/Home'));
const PrivacyPage = lazy(() => import('../views/Privacy'));
const SubscribePage = lazy(() => import('../views/emails/Subscribe'));
const UnsubscribePage = lazy(() => import('../views/emails/Unsubscribe'));
const Selection = lazy(() => import('../views/landings/Selection'));
const Investments = lazy(() => import('../views/investments/Home'));

const {PagePlaceholder} = Placeholder;

const BaseStack: React.FC = () => {
    const {t} = useTranslation(['urls', 'url']);

    return (
        <Routes>
            <Route
                path={t('home.client', {ns: 'urls'})}
                element={<HomePage/>}
            />
            <Route
                path={t('properties.client', {ns: 'urls'})}
                element={<PropertiesStack/>}
            />
            <Route
                path={t('developments.client', {ns: 'urls'})}
                element={<DevelopmentsStack/>}
            />
            <Route
                path={t('villages.client', {ns: 'urls'})}
                element={<EstatesStack/>}
            />
            <Route
                path={t('agents.client', {ns: 'urls'})}
                element={<AgentsStack/>}
            />
            <Route
                path={t('account.client', {ns: 'url'})}
                element={<AccountStack/>}
            />
            <Route
                path={t('emails.subscribe.client', {ns: 'urls'})}
                element={<SubscribePage/>}
            />
            <Route
                path={t('emails.subscribe.confirmed.client', {ns: 'urls'})}
                element={<SubscribePage/>}
            />
            <Route
                path={t('emails.unsubscribe.client', {ns: 'urls'})}
                element={<UnsubscribePage/>}
            />
            <Route
                path={t('emails.unsubscribe.confirmed.client', {ns: 'urls'})}
                element={<UnsubscribePage/>}
            />
            <Route
                path={t('landings.selection.main.client', {ns: 'url'})}
                element={<Selection/>}
            />
            <Route
                path={t('investments.home.client', {ns: 'url'})}
                element={<Investments/>}
            />
            <Route
                path='privacy'
                element={<PrivacyPage/>}
            />
            <Route
                path='*'
                element={<DynamicRoute/>}
            />
        </Routes>
    )
};

const RootStack: React.FC = () => {
    return (
        <React.Suspense fallback={<PagePlaceholder/>}>
            <Routes>
                <Route path={'ru/*'} element={<BaseStack/>}/>
                <Route path={'/*'} element={<BaseStack/>}/>
            </Routes>
        </React.Suspense>
    );
};

export {RootStack};
