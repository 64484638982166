import * as React from "react";
import {useAccount, useRouter} from "../hooks";
import * as Sentry from "@sentry/react";
import {useCookies} from "react-cookie";
import {EventName, EventProperties, eventsAllowList, rudderInitialize} from "../analytics";
import {ApiObject, RudderAnalytics} from "@rudderstack/analytics-js";
import {CLSMetric, FCPMetric, FIDMetric, INPMetric, LCPMetric, TTFBMetric} from "web-vitals";
import preparePath from "../helpers/urls/path";

type TTrackWebVitals = (metric: CLSMetric | FCPMetric | FIDMetric | INPMetric | LCPMetric | TTFBMetric) => void;

type TReportWebVitals = (onPerfEntry: TTrackWebVitals) => void;

type TProvider = {
    children: React.ReactNode
};

type UseAnalyticsContext = ReturnType<typeof useAnalyticsProvider>;

const analyticsContext = React.createContext<UseAnalyticsContext>({} as UseAnalyticsContext);

const AnalyticsProvider: React.FC<TProvider> = ({children}) => {
    const analytics = useAnalyticsProvider();

    return (
        <analyticsContext.Provider value={analytics}>
            {children}
        </analyticsContext.Provider>
    );
};

const useAnalytics = () => {
    return React.useContext(analyticsContext);
};

const useAnalyticsProvider = () => {
    const {user} = useAccount();
    const {query, location} = useRouter();
    const deferredPathname = React.useDeferredValue(preparePath(location.pathname) + location.search);
    const [{yclid, sputnik_anonymous_id}, setCookie] = useCookies(['yclid', 'sputnik_anonymous_id']);

    const metrikaAccountList: string = 'yandex_metrika_accounts';
    const metrikaCallbackQueue: string = 'yandex_metrika_callbacks2';

    React.useEffect(
        () => {
            if (query.yclid && query.yclid !== yclid) {
                let expires = new Date();
                expires.setTime(expires.getTime() + (90 * 24 * 60 * 60 * 1000));
                setCookie('yclid', query.yclid, {path: '/', expires});
            }
        },
        [query]
    );

    React.useEffect(
        () => {
            void rudderInitialize();
            void metrikaInitialize();

            let script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = 'https://mc.yandex.ru/metrika/tag.js';
            let first = document.getElementsByTagName('script')[0];
            if (first.parentNode) first.parentNode.insertBefore(script, first);
        },
        []
    );

    React.useEffect(
        () => {
            if (
                user.loggedIn &&
                'id' in user &&
                window.rudderanalytics &&
                'identify' in window.rudderanalytics
            ) (window.rudderanalytics as RudderAnalytics).identify(
                user.id,
                {},
                {
                    anonymousId: sputnik_anonymous_id,
                }
            );
        },
        [user.loggedIn]
    );

    React.useEffect(
        () => {
            const properties: ApiObject = {
                path: preparePath(location.pathname) + location.search,
                url: window.location.href,
                tab_url: window.location.href,
                search: window.location.search,
            };

            if (properties.path !== deferredPathname) properties.referrer = deferredPathname;

            if (window.rudderanalytics && 'page' in window.rudderanalytics) {
                (window.rudderanalytics as RudderAnalytics).page(
                    properties,
                    {
                        anonymousId: sputnik_anonymous_id,
                    },
                );
            }

            const ymProperties: { [k: string]: any } = {};

            if (properties.referrer) ymProperties.referer = cleanUrl(window.location.origin + properties.referrer);

            ym('hit', cleanUrl(window.location.origin + properties.path), ymProperties);
        },
        [location]
    );

    React.useEffect(
        () => reportWebVitals(trackWebVitals),
        []
    );

    React.useEffect(
        () => track('Application Opened'),
        []
    );

    const track = <T extends EventName>(event: T, properties?: EventProperties[T]): void => {
        try {
            if (!event || !eventsAllowList.includes(event)) return;
            if (window.rudderanalytics && 'track' in window.rudderanalytics) {
                (window.rudderanalytics as RudderAnalytics).track(
                    event,
                    properties as ApiObject || {},
                    {
                        anonymousId: sputnik_anonymous_id,
                    }
                )
            }
            ym(
                'reachGoal',
                event.split(' ').join(''),
                properties
            );
        } catch (e) {
            Sentry.captureException(e);
        }
    };

    const trackWebVitals: TTrackWebVitals = ({name, ...props}) => track(name, props);

    const metrikaIds = () => {
        return typeof window !== 'undefined' ? (window as any)[metrikaAccountList] : [];
    };

    const metrikaInstanceName = (id: string) => {
        return `yaCounter${id}`;
    };

    const metrikaInitialize = async () => {
        if (!process.env.REACT_APP_YANDEX_METRIKA_ACCOUNTS) throw new Error('Missing Yandex Metrika accounts');

        const accounts = process.env.REACT_APP_YANDEX_METRIKA_ACCOUNTS.split(',');

        (window as any)[metrikaAccountList] = (window as any)[metrikaAccountList] || [];
        (window as any)[metrikaAccountList] = (window as any)[metrikaAccountList].concat(accounts);

        (window as any)[metrikaCallbackQueue] = (window as any)[metrikaCallbackQueue] || [];
        (window as any)[metrikaCallbackQueue].push(() => {
            accounts.forEach((id) => {
                const defaultOptions = {
                    id,
                    defer: true,
                    accurateTrackBounce: true,
                    clickmap: true,
                    webvisor: true
                };

                const trackerInstanceName = metrikaInstanceName(id);
                const trackerConstructorName = 'Metrika2';

                try {
                    (window as any)[trackerInstanceName] = new (window as any).Ya[trackerConstructorName](
                        Object.assign(defaultOptions)
                    );
                } catch (e) {
                    Sentry.captureException(e);
                }
            });
        });

        accounts.forEach(id => {
            (window as any)[`yaCounterVersion${id}`] = '2';
        });
    };

    function ymProxy(...args: any[]) {
        let id: string = args[0];
        let methodName: string = args[1]
        try {
            (window as any)[metrikaInstanceName(id)][methodName](...args.slice(2));
        } catch (e) {
            Sentry.captureException(e);
        }
    }

    const ymAsyncProxy = (ids: string[]) => {
        return (...args: any[]) => {
            ids.forEach((id) => {
                let callbackQueue = (window as any)[metrikaCallbackQueue];
                args = [id, ...args];
                if (callbackQueue) {
                    callbackQueue.push(() => ymProxy(...args));
                } else {
                    ymProxy(...args);
                }
            });
        };
    };

    const ym = (...args: any[]) => {
        return ymAsyncProxy(metrikaIds())(...args);
    };

    const reportWebVitals: TReportWebVitals = (onPerfEntry) => {
        if (onPerfEntry) {
            import('web-vitals').then(({onCLS, onFCP, onFID, onINP, onLCP, onTTFB}) => {
                onCLS(onPerfEntry);
                onFCP(onPerfEntry);
                onFID(onPerfEntry);
                onINP(onPerfEntry);
                onLCP(onPerfEntry);
                onTTFB(onPerfEntry);
            });
        }
    };

    const cleanUrl = (url: string) => {
        return url.replace(/\/+$/, '');
    };

    return React.useMemo(() => ({track, ym}), []);
};

export {AnalyticsProvider, useAnalytics}
