import React from "react";
import styled from "styled-components";

const Wrapper = styled.span`
  display: block;
  font-size: ${({size, theme}) => theme.fontSize[size || 'xxs'].size};
  font-weight: 600;
  color: ${({type, filled, theme}) => filled ? theme.textColorInverse : theme.states[type]};
  border-color: ${({type, theme}) => theme.states[type]};
  background-color: ${({type, filled, theme}) => filled ? theme.states[type] : 'transparent'};
  border-style: solid;
  border-width: 1px;
  padding: 3px 15px;
  border-radius: 8px;
  margin-top: 3px;
`;

const Tag = ({type, size, filled, value}) => {
    return (
        <Wrapper type={type} size={size} filled={filled}>{value}</Wrapper>
    )
};

export {Tag};
