import * as React from "react";
import {IIconSVGProps} from "./types";

const ParkIcon: React.FC<IIconSVGProps> = ({color, className, ref}) => (
    <svg ref={ref} className={className} viewBox="0 0 15.8995052 20" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
                d="M14.2850468,7.70998365 C14.4029138,7.36190456 14.4693865,6.98982734 14.4693865,6.60203453 C14.4693865,4.89243316 13.2299786,3.47548156 11.601716,3.19175144 C11.462187,1.40612059 9.97175441,0 8.15044492,0 C6.3442139,0 4.86248858,1.38233483 4.7012976,3.14630365 C2.87828912,3.2476055 1.43011872,4.75375369 1.43011872,6.60182216 C1.43011872,6.98982734 1.49659142,7.36190456 1.61445834,7.70977127 C0.645401066,8.32437828 0,9.40344469 0,10.6356319 C0,12.549536 1.55159598,14.1013443 3.46528766,14.1013443 C4.29948818,14.1013443 5.0648799,13.8057213 5.66292183,13.3147153 C5.94601482,13.6069403 6.28220103,13.8462845 6.65257927,14.0251025 L6.65257927,20 L9.24735065,20 L9.24735065,14.1882048 C9.70034192,14.0359335 10.1125576,13.7953151 10.4608491,13.483127 C11.021301,13.8719817 11.7004693,14.1017691 12.4342175,14.1017691 C14.3481216,14.1017691 15.8995052,12.5497483 15.8995052,10.6360567 C15.8995052,9.40365706 15.2543165,8.32437828 14.2850468,7.70998365 Z"
                fill={color}
                fillRule="nonzero"
            />
        </g>
    </svg>
);

export default ParkIcon;
