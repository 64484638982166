import {createInstance} from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {defaultLocale, supportedLanguages} from "./constants";
import LazyImportPlugin from "./backend";

const i18n = createInstance();

const options = {
    ns: ['common', 'general'],
    defaultNS: 'common',
    fallbackLng: defaultLocale,
    supportedLngs: supportedLanguages,
    load: 'languageOnly' as const,
    interpolation: {
        escapeValue: false,
    },
    react: {
        useSuspense: true,
    },
    detection: {
        order: ['path']
    },
};

i18n.use(LanguageDetector);
i18n.use(LazyImportPlugin);
i18n.use(initReactI18next);

if (!i18n.isInitialized) {
    void i18n.init(options);
}

export default i18n;
