import * as React from "react";

export interface IServerContext {
    statusCode: number;
    redirect?: string;
}

interface IServerProvider {
    children: React.ReactNode;
    context: IServerContext;
}

const ServerContext = React.createContext<null | IServerContext>(null);

const ServerProvider: React.FC<IServerProvider> = ({children, context}) => (
    <ServerContext.Provider value={context}>
        {children}
    </ServerContext.Provider>
);

const useServer = () => {
    return React.useContext(ServerContext);
};

export {ServerProvider, useServer};
