import {useMemo} from "react";
import {Location, NavigateFunction, PathMatch, useLocation, useMatch, useNavigate, useParams} from "react-router-dom";
import qs from "qs";
import preparePath from "../helpers/urls/path";

interface IUseRouter {
    pathname: string;
    query: { [k: string]: string }
    location: Location;
    match: PathMatch;
    navigate: NavigateFunction;
}

const useRouter = () => {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const match = useMatch(location.pathname);

    return useMemo(() => {
        return {
            pathname: preparePath(location.pathname),
            query: {
                ...qs.parse(location.search, {ignoreQueryPrefix: true}),
                ...params
            },
            location,
            match,
            navigate
        } as IUseRouter;
    }, [params, location, match, navigate]);
}

export default useRouter;
