import {PluginName, RudderAnalytics} from "@rudderstack/analytics-js";
import * as Sentry from "@sentry/react";

export async function rudderInitialize() {
    (function () {
        const sdkBaseUrl = 'https://cdn.rudderlabs.com/beta/v3';
        const sdkName = 'rsa.min.js';
        const asyncScript = true;
        window.rudderAnalyticsBuildType = 'legacy';

        window.rudderanalytics = [] as any;

        const methods: string[] = [
            'setDefaultInstanceKey',
            'load',
            'ready',
            'page',
            'track',
            'identify',
            'alias',
            'group',
            'reset',
            'setAnonymousId',
            'startSession',
            'endSession',
        ];

        for (let i = 0; i < methods.length; i++) {
            const method: string = methods[i];
            (window.rudderanalytics as any)[method] = (methodName =>
                function () {
                    (window.rudderanalytics as any).push([methodName].concat(Array.prototype.slice.call(arguments)));
                })(method);
        }

        try {
            new Function('return import("")');
            window.rudderAnalyticsBuildType = 'modern';
        } catch (e) {
            Sentry.captureException(e);
        }

        window.rudderAnalyticsMount = () => {
            const rudderAnalyticsScript = document.createElement('script');
            rudderAnalyticsScript.src = `${sdkBaseUrl}/${window.rudderAnalyticsBuildType}/${sdkName}`;
            rudderAnalyticsScript.async = asyncScript;
            if (document.head) {
                document.head.appendChild(rudderAnalyticsScript);
            } else {
                document.body.appendChild(rudderAnalyticsScript);
            }
        };

        if (typeof Promise === 'undefined') {
            const rudderAnalyticsPromisesScript = document.createElement('script');
            rudderAnalyticsPromisesScript.src =
                'https://polyfill.io/v3/polyfill.min.js?features=globalThis%2CPromise&callback=rudderAnalyticsMount';
            rudderAnalyticsPromisesScript.async = asyncScript;
            if (document.head) {
                document.head.appendChild(rudderAnalyticsPromisesScript);
            } else {
                document.body.appendChild(rudderAnalyticsPromisesScript);
            }
        } else {
            window.rudderAnalyticsMount();
        }

        const loadOptions = {
            plugins: ['XhrQueue'] as PluginName[],
        };

        if (window.rudderanalytics && 'load' in window.rudderanalytics) {
            (window.rudderanalytics as RudderAnalytics).load(
                process.env.REACT_APP_RUDDER_WRITE_KEY || '',
                'https://sputnikawhuwsd.dataplane.rudderstack.com',
                loadOptions
            );
        }
    })();
}
