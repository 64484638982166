import * as React from "react";
import {IIconSVGProps} from "./types";

const TransportIcon: React.FC<IIconSVGProps> = ({color, className, ref}) => (
    <svg ref={ref} className={className} viewBox="0 0 16 16" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.67845 3.60777C2.86542 2.67292 3.68625 2 4.63961 2H11.3604C12.3138 2 13.1346 2.67292 13.3216 3.60777L14 7C14.5523 7 15 7.44772 15 8V9C15 9.55228 14.5523 10 14 10V11V12V13C14 13.5523 13.5523 14 13 14H12C11.4477 14 11 13.5523 11 13V12H5V13C5 13.5523 4.55228 14 4 14H3C2.44772 14 2 13.5523 2 13V12V11L2 10C1.44772 10 1 9.55228 1 9V8C1 7.44772 1.44772 7 2 7L2.67845 3.60777ZM3.51478 3.70543C3.57765 3.30302 4.02553 3 4.55741 3H11.4426C11.9745 3 12.4223 3.30302 12.4852 3.70543L12.8196 5.84562C12.9144 6.4521 12.4454 7 11.8316 7H4.16838C3.55455 7 3.08561 6.4521 3.18037 5.84562L3.51478 3.70543ZM3 9C3 8.44772 3.44772 8 4 8C4.55228 8 5 8.44772 5 9C5 9.55228 4.55228 10 4 10C3.44772 10 3 9.55228 3 9ZM12 8C11.4477 8 11 8.44772 11 9C11 9.55228 11.4477 10 12 10C12.5523 10 13 9.55228 13 9C13 8.44772 12.5523 8 12 8Z"
            fill={color}
        />
    </svg>
);

export default TransportIcon;
