import * as React from "react";
import {twMerge} from "tailwind-merge";
import {CheckIcon} from "@heroicons/react/20/solid";
import {valueFromType, valueToType} from "../../helpers";
import {FieldError, Merge} from "react-hook-form";
import {TOnChange} from "../../types/fields";

export type TChoicesNotTypedValue = string | string[] | number | number[] | boolean | null | undefined;

type TChoicesState = string | string[] | null | undefined;

export type TChoicesType = 'radio' | 'checkbox';

export type TChoicesStyle = 'full' | 'buttons';

export type TChoicesValueType = 'text' | 'number' | 'bool';

interface IChoicesBase {
    value?: string | string[] | number | number[] | boolean;
    valueType: TChoicesValueType;
    onChange: TOnChange;
    type: TChoicesType;
    style: TChoicesStyle;
    error?: Merge<FieldError, (FieldError | undefined)[]> | undefined;
    children: React.ReactNode;
    ref?: React.Ref<HTMLInputElement>;
}

interface IChoices extends IChoicesBase {
    label?: string;
    name?: string;
    containerClassName?: string | null;

}

interface IChoice extends Partial<IChoicesBase> {
    className?: string | null;
    choice: string | number | boolean;
    children: React.ReactNode;
}

export const Choices: React.FC<IChoices> = (
    {
        label,
        value,
        type,
        style = 'full',
        valueType,
        error,
        containerClassName,
        onChange,
        children,
        ref,
        ...rest
    }
) => {
    const getTypedValue = (value: TChoicesNotTypedValue) => {
        if (value !== undefined) {
            if (type === 'radio') return valueFromType(value, valueType);
            if (type === 'checkbox' && Array.isArray(value)) return value.map((i) => valueFromType(i, valueType));
        } else {
            if (type === 'radio') {
                return null;
            }
            if (type === 'checkbox') return [];
        }
    };

    const [typedValue, setTypedValue] = React.useState<TChoicesState>(getTypedValue(value));

    React.useEffect(
        () => {
            if (value === undefined) {
                setTypedValue(getTypedValue(value));
            }
        },
        [value]
    );

    React.useEffect(
        () => {
            if (typedValue !== undefined) {
                if (type === 'checkbox') {
                    if (Array.isArray(typedValue) && typedValue?.length > 0) {
                        const v = typedValue.map((i) => valueToType(i, valueType));
                        if (value !== v) onChange(v);
                    } else {
                        if (value !== null) onChange(null);
                    }
                } else {
                    const v = valueToType(typedValue, valueType)
                    if (value !== v) {
                        onChange(v);
                    }
                }
            }
        },
        [typedValue]
    );

    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        if (type === 'radio') setTypedValue(value);

        if (type === 'checkbox') setTypedValue((data) => {
            if (Array.isArray(data)) {
                if (data.includes(value)) return data.filter((i) => i !== value);
                return [...data, value];
            }
            return data;
        });
    };

    const className = React.useMemo(
        () => {
            let classes: string[] = [];
            if (style === 'full') classes = [...classes, 'grid', 'grid-cols-1', 'md:grid-cols-2', 'gap-5'];
            if (style === 'buttons') classes = [...classes, 'flex', 'flex-row', 'gap-2', 'flex-wrap']
            return twMerge(classes.join(' '), containerClassName);
        },
        [style, containerClassName]
    );

    return (
        <div>
            {label && <div className="text-xs text-gray-500 mb-2 leading-3">{label}</div>}
            <div className={className}>
                {children && React.Children.map(children, (child) => (
                    <>
                        {React.isValidElement(child) && React.cloneElement(child, {
                            type,
                            style,
                            ...rest,
                            ...child.props,
                            valueType,
                            value: typedValue,
                            onChange: onChangeHandler,
                            error,
                            ref
                        })}
                    </>
                ))}
            </div>
            {error && <div className="text-xs text-red-500 mt-3 -mb-1">{error.message}</div>}
        </div>
    );
};

export const Choice: React.FC<IChoice> = (
    {
        type,
        style,
        choice,
        children,
        value,
        valueType,
        error,
        className,
        ref,
        ...props
    }
) => {
    const typedChoice = React.useMemo(
        () => valueFromType(choice, valueType),
        [choice]
    );

    const checked = React.useMemo(
        () => {
            if (type === 'radio') return value === typedChoice;
            if (type === 'checkbox' && Array.isArray(value)) {
                return value.some((i) => i === typedChoice);
            }
            return false;
        },
        [value, typedChoice]
    );

    const containerClassName = React.useMemo(
        () => {
            let classes = ['group', 'transition-colors', 'hover:cursor-pointer'];

            if (style === 'full') classes.push('rounded-2xl', 'py-4', 'px-5');
            if (style === 'buttons') classes.push(
                'flex',
                'justify-center',
                'items-center',
                'rounded-full',
                'h-12',
                'min-w-[3rem]',
                'sm:h-14',
                'sm:min-w-[3.5rem]',
                'px-6',
                'sm:px-8',
                'text-sm',
                'sm:text-base'
            );

            if (checked) {
                classes.push('bg-blue-500')
            } else {
                if (error) classes.push('bg-red-100');
                else classes.push('bg-cultured-100', 'hover:bg-blue-25');
            }

            return classes.join(' ');
        },
        [style, checked, error]
    );

    const labelClassName = React.useMemo(
        () => {
            let classes = ['font-semibold', 'transition-colors'];

            if (style === 'full') classes.push('ml-2.5 text-left');
            if (style === 'buttons') classes.push();

            if (checked) {
                classes.push('text-white', '[&>*]:text-white')
            } else {
                if (error) classes.push('text-red-500', '[&>*]:text-red-500', 'group-hover:text-cultured-700', '[&>*]:group-hover:text-gray-500');
                else classes.push('text-cultured-700', '[&>*]:text-gray-500');
            }

            return classes.join(' ');
        },
        [style, checked, error]
    );

    return (
        <label className={twMerge(containerClassName, className)}>
            <input
                ref={ref}
                className="absolute invisible"
                type={type}
                value={typedChoice}
                {...props} checked={checked}
            />
            <div className="flex flex-row">
                {style === 'full' && (
                    <div
                        className={twMerge(
                            'flex items-center justify-center shrink-0 w-5 h-5 rounded-full border border-cultured-150 bg-white mt-0.5',
                            checked ? 'border-blue-500' : 'border-cultured-150',
                            error && 'border-red-200 group-hover:border-cultured-150'
                        )}
                    >
                        {checked && <CheckIcon className="h-4 w-4 text-blue-500"/>}
                    </div>
                )}
                <div className={labelClassName}>
                    {children}
                </div>
            </div>
        </label>
    );
};
