import * as React from "react";
import {twMerge} from "tailwind-merge";
import {IButton} from "../types";
import Icon from "../../../Icon";

const Primary: React.FC<IButton> = (
    {
        title,
        type,
        prefix,
        suffix,
        className,
        titleClassName,
        disabled = false,
        loading = false,
        loadingColor = '#FFFFFF',
        onClick,
        ref,
    }
) => (
    <button
        ref={ref}
        type={type}
        onClick={onClick}
        disabled={disabled}
        className={twMerge(
            'flex w-full h-12 sm:h-14 items-center justify-center gap-3 rounded-full border border-transparent bg-blue-500 text-base font-semibold text-white hover:bg-blue-400 focus:outline-none focus:ring-0',
            (loading || disabled) && 'bg-blue-400',
            className,
        )}
    >
        {loading ? (
            <Icon
                name="loader"
                color={loadingColor}
                className="h-6 w-6 animate-spin"
            />
        ) : (
            <>
                {prefix && prefix}
                {title && <span className={twMerge(titleClassName)}>{title}</span>}
                {suffix && suffix}
            </>
        )}
    </button>
);

export default Primary;
