import * as React from "react";
import lazy from '@fatso83/retry-dynamic-import/react-lazy';
import {Route, Routes} from "react-router-dom";
import {PropertiesPageType} from "../views/Properties/Properties";
import {useTranslation} from "react-i18next";
import {PropertyStack} from "./PropertyStack";
import {StaffProtected} from "./Protected";

const PropertiesPage = lazy(() => import('../views/Properties/Properties')) as unknown as PropertiesPageType;
const PropertyCreatePage = lazy(() => import('../views/Properties/Create'));

const PropertiesStack: React.FC = () => {
    const {t} = useTranslation('urls');

    return (
        <Routes>
            <Route
                path="/"
                element={
                    <PropertiesPage
                        metaPath={'properties.core.all'}
                        urlPath={'properties.absolute'}
                        header={{
                            title: 'properties.core.all.main.header.title',
                            description: 'properties.core.all.main.header.description',
                        }}
                    />
                }
            />
            <Route
                path={':id/*'}
                element={
                    <PropertyStack objective="plain"/>
                }
            />
            <Route
                path={t('properties.add.client')}
                element={<PropertyCreatePage/>}
            />
            <Route
                path={t('properties.sale.client')}
                element={
                    <PropertiesPage
                        metaPath={'properties.core.all.sale'}
                        urlPath={'properties.sale.absolute'}
                        initial={{
                            type: 'sale',
                        }}
                        header={{
                            title: 'properties.core.all.sale.main.header.title',
                            description: 'properties.core.all.sale.main.header.description',
                        }}
                    />
                }
            />
            <Route
                path={t('properties.sale.flat.client')}
                element={
                    <PropertiesPage
                        metaPath={'properties.core.all.sale.flat'}
                        urlPath={'properties.sale.flat.absolute'}
                        initial={{
                            type: 'sale',
                            property__kind: 'flat',
                        }}
                        header={{
                            title: 'properties.core.all.sale.flat.header.title',
                            description: 'properties.core.all.sale.flat.header.description',
                        }}
                    />
                }
            />
            <Route
                path={t('properties.sale.house.client')}
                element={
                    <PropertiesPage
                        metaPath={'properties.core.all.sale.house'}
                        urlPath={'properties.sale.house.absolute'}
                        initial={{
                            type: 'sale',
                            property__kind: 'house',
                        }}
                        header={{
                            title: 'properties.core.all.sale.house.header.title',
                            description: 'properties.core.all.sale.house.header.description',
                        }}
                    />
                }
            />
            <Route
                path={t('properties.sale.townhouse.client')}
                element={
                    <PropertiesPage
                        metaPath={'properties.core.all.sale.townhouse'}
                        urlPath={'properties.sale.townhouse.absolute'}
                        initial={{
                            type: 'sale',
                            property__kind: 'townhouse',
                        }}
                        header={{
                            title: 'properties.core.all.sale.townhouse.header.title',
                            description: 'properties.core.all.sale.townhouse.header.description',
                        }}
                    />
                }
            />
            <Route
                path={t('properties.sale.land.client')}
                element={
                    <PropertiesPage
                        metaPath={'properties.core.all.sale.land'}
                        urlPath={'properties.sale.land.absolute'}
                        initial={{
                            type: 'sale',
                            property__kind: 'land',
                        }}
                        header={{
                            title: 'properties.core.all.sale.land.header.title',
                            description: 'properties.core.all.sale.land.header.description',
                        }}
                    />
                }
            />
            <Route
                path={t('properties.lease.client')}
                element={
                    <PropertiesPage
                        metaPath={'properties.core.all.lease'}
                        urlPath={'properties.lease.absolute'}
                        initial={{
                            type: 'lease',
                        }}
                        header={{
                            title: 'properties.core.all.lease.main.header.title',
                            description: 'properties.core.all.lease.main.header.description',
                        }}
                    />
                }
            />
            <Route
                path={t('properties.lease.flat.client')}
                element={
                    <PropertiesPage
                        metaPath={'properties.core.all.lease.flat'}
                        urlPath={'properties.lease.flat.absolute'}
                        initial={{
                            type: 'lease',
                            property__kind: 'flat',
                        }}
                        header={{
                            title: 'properties.core.all.lease.flat.header.title',
                            description: 'properties.core.all.lease.flat.header.description',
                        }}
                    />
                }
            />
            <Route
                path={t('properties.lease.house.client')}
                element={
                    <PropertiesPage
                        metaPath={'properties.core.all.lease.house'}
                        urlPath={'properties.lease.house.absolute'}
                        initial={{
                            type: 'lease',
                            property__kind: 'house',
                        }}
                        header={{
                            title: 'properties.core.all.lease.house.header.title',
                            description: 'properties.core.all.lease.house.header.description',
                        }}
                    />
                }
            />
            <Route
                path={t('properties.lease.townhouse.client')}
                element={
                    <PropertiesPage
                        metaPath={'properties.core.all.lease.townhouse'}
                        urlPath={'properties.lease.townhouse.absolute'}
                        initial={{
                            type: 'lease',
                            property__kind: 'townhouse',
                        }}
                        header={{
                            title: 'properties.core.all.lease.townhouse.header.title',
                            description: 'properties.core.all.lease.townhouse.header.description',
                        }}
                    />
                }
            />
            <Route element={<StaffProtected/>}>
                <Route
                    path={t('properties.add.client')}
                    element={<PropertyCreatePage/>}
                />
            </Route>
        </Routes>
    );
};

export {PropertiesStack};
