import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
`;

const PagePlaceholder = () => (
    <Wrapper/>
);

export {PagePlaceholder};
