import {setFormErrors, setFormFieldError} from "./forms";
import {strToBool} from "../utils";
import {toInteger, toString} from "lodash";

export const idFromSlug = (slug) => {
    const slugParts = slug.split('-');
    return parseInt(slugParts[slugParts.length - 1]).toString();
};

export const valueToType = (value, valueType) => {
    if (valueType === 'bool') return strToBool(value);
    if (valueType === 'number') return toInteger(value);
    return value;
};

export const valueFromType = (value, valueType) => {
    if (valueType === 'bool') return toString(value);
    if (valueType === 'number') return toString(value);
    return value;
};

export const getNumericValue = (value) => {
    if (value === undefined) return null;
    return value;
};

export {
    setFormFieldError,
    setFormErrors,
}
