import * as React from "react";
import {IIconSVGProps} from "./types";

const SchoolIcon: React.FC<IIconSVGProps> = ({color, className, ref}) => (
    <svg ref={ref} className={className} viewBox="0 0 20 19" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path
                d="M0.3099,1.7275 C0.1043,1.8951 0,2.149 0,2.4142 L0,13.4935 C0.1204,13.4175 0.2481,13.3404 0.3827,13.2635 C1.4599,12.648 3.0524,12 5,12 C6.604,12 7.9672,12.4395 9,12.9385 L9,1.7729 C9,1.4331 8.8298,1.1127 8.5326,0.948 C7.663,0.4659 6.448,0 5,0 C2.6953,0 0.9808,1.1804 0.3099,1.7275 Z M5,14 C2,14 0,16 0,16 L0,17 C0,17.5523 0.4477,18 1,18 L9,18 C9,18.5523 9.4477,19 10,19 C10.5523,19 11,18.5523 11,18 L19,18 C19.5523,18 20,17.5523 20,17 L20,16 C20,16 18,14 15,14 C12,14 10,16 10,16 C10,16 8,14 5,14 Z M11,1.7729 C11,1.4331 11.1702,1.1127 11.4674,0.948 C12.337,0.4659 13.552,0 15,0 C17.3047,0 19.0192,1.1804 19.6901,1.7275 C19.8957,1.8951 20,2.149 20,2.4142 L20,13.4935 C19.8796,13.4175 19.7519,13.3404 19.6173,13.2635 C18.5401,12.648 16.9476,12 15,12 C13.396,12 12.0328,12.4395 11,12.9385 L11,1.7729 Z"
                fill={color}
            />
        </g>
    </svg>
);

export default SchoolIcon;
