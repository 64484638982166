import * as React from "react";
import {useRef, useState} from "react";
import ReactPlayer from "react-player";
import {PauseIcon, PlayIcon} from "@heroicons/react/24/solid";
import {useAccount} from "../../hooks";
import Slider from "rc-slider";
import {OnProgressProps} from "react-player/base";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

interface PlayerProps {
    url: string;
}

interface StateProps {
    url?: string;
    playing: boolean;
    seeking: boolean;
    volume: number;
    muted: boolean;
    duration: number;
    loop: boolean;
}

const Player: React.FC<PlayerProps> = ({url}) => {
    const {user} = useAccount();

    const [state, setState] = useState<StateProps>({
        playing: false,
        seeking: false,
        volume: 1,
        muted: false,
        duration: 0,
        loop: false
    });

    const [progress, setProgress] = useState<OnProgressProps>({
        played: 0,
        loaded: 0,
        loadedSeconds: 0,
        playedSeconds: 0,
    });

    const player = useRef<ReactPlayer>(null);

    React.useEffect(
        () => {
            if ('token' in user) {
                const getFile = async () => {
                    const result = await fetch(url, {
                        headers: {
                            Authorization: `Token ${user.token}`
                        }
                    });

                    const blob = await result.blob();

                    if (blob) {
                        setState({...state, url: URL.createObjectURL(blob)});
                    }
                };

                void getFile();
            }
        },
        [url]
    );

    const handlePlayPause = () => {
        setState({...state, playing: !state.playing});
    };

    const handlePlay = () => {
        setState({...state, playing: true});
    };

    const handlePause = () => {
        setState({...state, playing: false});
    };

    const handleEnded = () => {
        setState({...state, playing: state.loop});
    };

    const handleSeekChange = (value: number | number[]) => {
        if (Array.isArray(value)) value = value[0];
        setState({...state, seeking: true});
        setProgress({...progress, played: value});
    };

    const handleSeekComplete = (value: number | number[]) => {
        if (player.current) {
            if (Array.isArray(value)) value = value[0];
            setState({...state, seeking: false});
            player.current.seekTo(value);
        }
    };

    const handleProgress = (progress: OnProgressProps) => {
        if (!state.seeking) setProgress(progress);
    };

    const handleDuration = (duration: number) => {
        setState({...state, duration: duration});
    };

    return (
        <div>
            <ReactPlayer
                ref={player}
                width='100%'
                height='100%'
                url={state.url}
                playing={state.playing}
                volume={state.volume}
                muted={state.muted}
                onPlay={handlePlay}
                onPause={handlePause}
                onEnded={handleEnded}
                onDuration={handleDuration}
                onProgress={handleProgress}
                config={{
                    file: {
                        forceAudio: true,
                    }
                }}
            />
            <div className="grid grid-cols-12 grid-rows-2 md:grid-rows-1">
                <div
                    className="row-start-1 col-span-full md:col-start-3 md:col-span-10 xl:col-start-2 xl:col-span-11 flex flex-col justify-end md:justify-center md:mt-2 gap-1"
                >
                    <Slider
                        min={0}
                        max={1}
                        value={progress.played}
                        step={0.001}
                        onChange={handleSeekChange}
                        onChangeComplete={handleSeekComplete}
                    />
                    <div className="flex justify-between text-slate-900 text-xs md:text-sm">
                        <div>
                            {dayjs.duration(progress.playedSeconds, 's').format('m:ss')}
                        </div>
                        <div>
                            {dayjs.duration(state.duration - progress.playedSeconds, 's').format('m:ss')}
                        </div>
                    </div>
                </div>
                <div
                    className="row-start-2 md:row-start-1 col-span-full md:col-start-1 md:col-span-2 xl:col-start-1 xl:col-span-1 flex flex-row justify-center md:justify-start"
                >
                    <button
                        className="flex justify-center items-center h-12 w-12 bg-slate-900 rounded-full"
                        onClick={handlePlayPause}
                    >
                        {state.playing ? (
                            <PauseIcon
                                className="h-6 w-6 text-white"
                            />
                        ) : (
                            <PlayIcon
                                className="h-6 w-6 text-white"
                            />
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Player;
