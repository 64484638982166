import {Organization} from "schema-dts";
import JsonLd from "../index";

const organization = {
    en: JsonLd<Organization>({
        '@context': 'https://schema.org',
        '@type': 'Organization',
        name: 'Sputnik',
        url: 'https://sputnik.estate',
        logo: 'https://sputnik.estate/logo512.png',
        telephone: '+7 (495) 414-10-94',
        address: {
            '@type': 'PostalAddress',
            streetAddress: 'Bolshaya Yakimanka Street, 26',
            addressLocality: 'Moscow',
            addressCountry: 'Russia',
            postalCode: '119180',
        },
        aggregateRating: {
            '@type': 'AggregateRating',
            'ratingValue': 5,
            'reviewCount': 4,
            'bestRating': 5,
        }
    }),
    ru: JsonLd<Organization>({
        '@context': 'https://schema.org',
        '@type': 'Organization',
        name: 'Спутник',
        url: 'https://sputnik.estate/ru',
        logo: 'https://sputnik.estate/logo512.png',
        telephone: '+7 (495) 414-10-94',
        address: {
            '@type': 'PostalAddress',
            streetAddress: 'улица Большая Якиманка, 26',
            addressLocality: 'Москва',
            addressCountry: 'Россия',
            postalCode: '119180',
        },
        aggregateRating: {
            '@type': 'AggregateRating',
            'ratingValue': 5,
            'reviewCount': 4,
            'bestRating': 5,
        }
    })
};

export default organization;
