import React from "react";
import {ReactComponent as EmptyIcon} from '../../static/empty.svg';
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({theme}) => theme.colors.gray[5]};
  font-size: ${({theme}) => theme.fontSize.s.size};
  margin-top: ${({mt}) => mt ? `${mt}px` : 0};
  margin-bottom: ${({mb}) => mb ? `${mb}px` : 0};
`;

const Message = styled.div`
  margin-top: 10px;
`;

const Icon = ({icon}) => {
    return (
        <>
            {icon ? icon : <EmptyIcon/>}
        </>
    );
};

const Empty = ({mt, mb, message, withIcon = true, icon}) => {
    return (
        <Wrapper mt={mt} mb={mb}>
            {withIcon && <Icon icon={icon}/>}
            <Message>
                {message ? message : 'Нет данных'}
            </Message>
        </Wrapper>
    )
};

export {Empty};
