import * as React from "react";
import {TSetForm} from "../../types";
import {useTranslation} from "react-i18next";
import Input from "../../../../Fields/Input";
import {GeneratePasswordResult, useGeneratePassword} from "@sputnikestate/graph";
import {Form} from "../../../../../";
import PrimaryButton from "../../../../../primitives/Buttons/Primary";
import toast from "react-hot-toast";
import {useLoginStore} from "@sputnikestate/store";
import {IForm} from "../Form";
import {twMerge} from "tailwind-merge";
import {useAnalytics, useRouter} from "../../../../../../hooks";
import Link from "../../../../../Link";

interface IGenerate extends IForm {
    setForm: TSetForm;
}

const Generate: React.FC<IGenerate> = (
    {
        setForm,
        titleClassName,
        descriptionClassName,
        generateTitle,
        generateDescription,
        formContainerClassName,
        generateHelpText,
        generateButtonTitle,
        showPrivacyDisclaimer,
    }
) => {
    const [formError, setFormError] = React.useState<{
        [key: string]: any
    }>({});
    const {t, i18n} = useTranslation(['general']);
    const analytics = useAnalytics();
    const {query} = useRouter();

    const loginTitle = useLoginStore((state) => state.title);
    const loginDescription = useLoginStore((state) => state.description);

    const onSuccess = (data: GeneratePasswordResult) => {
        setForm('validate', data.phone);
        analytics.track('Login OTP Generated');
    };

    const {
        control,
        setFocus,
        setValue,
        onSubmit,
        formState: {errors, isSubmitting},
    } = useGeneratePassword({
        onSuccess,
        setFormError
    });

    React.useEffect(
        () => setFocus('phone'),
        []
    );

    React.useEffect(
        () => {
            if ('sct' in query) setValue('contactId', query.sct);
        },
        [query]
    );

    React.useEffect(() => {
        if (formError.detail?.message) {
            toast.error(formError.detail.message);
            setFormError(errors => {
                delete errors.detail.message;
                return errors;
            })
        }
        if (formError.nonFieldErrors?.message) {
            toast.error(formError.nonFieldErrors.message);
            setFormError(errors => {
                delete errors.nonFieldErrors.message;
                return errors;
            })
        }
    }, [formError]);

    const title = React.useMemo(
        () => {
            if (generateTitle === null) return null;
            if (generateTitle) return generateTitle;
            if (loginTitle) return loginTitle;
            return t('login.generate.title', {ns: 'general'});
        },
        [generateTitle, loginTitle],
    );

    const description = React.useMemo(
        () => {
            if (generateDescription === null) return null;
            if (generateDescription) return generateDescription;
            if (loginDescription) return loginDescription;
            return t('login.generate.description', {ns: 'general'});
        },
        [generateDescription, loginDescription],
    );

    const disclaimer = React.useMemo(
        () => {
            if (i18n.language === 'ru') return (
                <>
                    Авторизуясь на сайте, я принимаю условия и даю согласие на обработку персональных данных в
                    соответствии с законодательством России и <Link to="/privacy" target="_blank"
                                                                    className="text-blue-500">политикой
                    защиты персональной информации пользователей сайта</Link>.
                </>
            );
            return (
                <>
                    By authorizing on the site, I accept the terms and consent to the processing of personal data in
                    accordance with Russian law and <Link to="/privacy" target="_blank" className="text-blue-500">the
                    policy of
                    protection of personal information of users of the site</Link>.
                </>
            );
        },
        [i18n.language]
    );

    return (
        <div className="flex flex-col items-center justify-center w-full min-h-[inherit]">
            <div className="md:max-w-md mx-auto w-full">
                {title && (
                    <h1
                        className={twMerge(
                            'text-slate-900 font-black text-4xl sm:text-5xl tracking-tight text-center',
                            titleClassName,
                        )}
                    >
                        {title}
                    </h1>
                )}
                {description && (
                    <div
                        className={twMerge(
                            'text-center font-medium text-slate-600 text-base sm:text-xl mt-4 md:mt-6',
                            descriptionClassName,
                        )}
                    >
                        {description}
                    </div>
                )}
                <div className={twMerge('mt-10', formContainerClassName)}>
                    <Form onSubmit={onSubmit}>
                        <Input
                            name="phone"
                            valueType="text"
                            inputContainerClassName="h-16 sm:h-16"
                            inputClassName="text-2xl"
                            error={errors.phone}
                            control={control}
                        />
                        {!errors.phone && (
                            <div className="text-xs text-slate-900 mt-1.5">
                                {generateHelpText
                                    ? generateHelpText
                                    : t('login.generate.phoneExample', {ns: 'general'})
                                }
                            </div>
                        )}
                        <PrimaryButton
                            type="submit"
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            title={generateButtonTitle || t('login.generate.getCode', {ns: 'general'})}
                            className="h-14 lg:h-16 lg:text-lg mt-7"
                        />
                    </Form>
                </div>
                {showPrivacyDisclaimer && (
                    <div className="text-xs text-slate-500 text-center mt-9">
                        {disclaimer}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Generate;
