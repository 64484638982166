import * as React from "react";
import {ScrollElement} from "react-scroll";

interface IElement extends React.HTMLProps<HTMLDivElement> {
    name: string;
    parentBindings?: any;
    children: React.ReactNode;
}

const Element: React.FC<IElement> = ({parentBindings, children, name, ...props}) => {
    return (
        <div
            id={name}
            {...props}
            ref={(el) => {
                parentBindings.domNode = el;
            }}
        >
            {children}
        </div>
    );
};

export default ScrollElement<IElement>(Element as any);
