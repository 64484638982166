import * as React from "react";
import {IIconSVGProps} from "./types";

const KindergartenIcon: React.FC<IIconSVGProps> = ({color, className, ref}) => (
    <svg ref={ref} className={className} viewBox="0 0 20.0003023 19" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(0.104771, 0.000000)">
                <path
                    d="M0.895379965,15 C0.895379965,15 2.69537996,18 9.89537996,18 C17.09538,18 18.89538,15 18.89538,15"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.85047996,8.1794 L2.89537996,16 L4.89537996,17 C4.89537996,17 6.39537996,12.5 10.39538,12.5 C14.39538,12.5 15.89538,17 15.89538,17 L17.89538,16 L15.57918,9.0513 C15.17078,7.8263 14.02438,7 12.73308,7 L10.89538,7 C10.34308,7 9.89537996,6.5523 9.89537996,6 L9.89537996,5.7082 C9.89537996,5.2425 9.78697996,4.7831 9.57867996,4.3666 L8.59057996,2.3904 C8.15457996,1.5185 7.32227996,0.9128 6.35857996,0.7661 L1.32397996,0 L1.83957996,1.8046 C1.87537996,1.9299 1.86077996,2.0642 1.79897996,2.179 L0.0597799647,5.409 C-0.0391200353,5.5926 -0.0136200353,5.8183 0.123779965,5.9753 L1.54347996,7.5978 C1.73387996,7.8155 2.06877996,7.8266 2.27327996,7.6221 L3.40487996,6.4905 C3.64617996,6.2492 4.05297996,6.3152 4.20557996,6.6205 L4.81267996,7.8346 C4.86607996,7.9413 4.87947996,8.0636 4.85047996,8.1794 Z"
                    fill={color}
                    fillRule="nonzero"
                />
                <path
                    d="M15.01258,6.1018 L12.89538,8 L13.89538,9 L15.29898,8.1706 C16.32268,7.5657 17.62608,7.7306 18.46688,8.5714 L19.89538,5.7143 L19.47158,5.5024 C18.00308,4.7681 16.23508,5.0058 15.01258,6.1018 Z"
                    fill={color}
                    fillRule="nonzero"
                />
            </g>
        </g>
    </svg>
);

export default KindergartenIcon;
