import "dayjs/locale/en";
import "dayjs/locale/ru";
import * as React from "react";
import {AccountProvider, AnalyticsProvider, BreakpointsProvider} from "../hooks";
import * as Sentry from "@sentry/react";
import {Helmet} from "react-helmet-async";
import {Routes} from "../routes";
import Toaster from "../components/Toaster";
import CallActionModal from "../components/composites/Modals/CallActionModal";
import {useTranslation} from "react-i18next";
import {ListingProvider} from "@sputnikestate/store";
import organizationJsonLd from "../components/json+ld/organization";
import Login from "../components/composites/overlays/Login";
import Editor from "../components/composites/overlays/Editor/Overlay";

let App: React.FC = () => {
    const {t, i18n} = useTranslation('meta');

    return (
        <BreakpointsProvider>
            <AccountProvider>
                <Helmet>
                    <title>Спутник</title>
                    <meta
                        name="description"
                        content="Поможем найти дом, который вы полюбите, быстро и без комиссии."
                    />
                    <meta property="og:type" content="website"/>
                    <meta property="og:site_name" content={t('og.siteName')}/>
                    <meta property="og:image" content={'https://sputnik.estate/logo512.png'}/>
                </Helmet>
                <AnalyticsProvider>
                    <ListingProvider>
                        <Routes/>
                        <Toaster/>
                        <Login/>
                        <Editor/>
                        <CallActionModal/>
                        {(organizationJsonLd as any)[i18n.language]}
                    </ListingProvider>
                </AnalyticsProvider>
            </AccountProvider>
        </BreakpointsProvider>
    )
};

App = Sentry.withProfiler(App);

export {App};
