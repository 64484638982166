import * as React from "react";
import {twMerge} from "tailwind-merge";

interface InputErrorProps {
    children?: string;
    inputErrorClassName?: string;
}

const InputError: React.FC<InputErrorProps> = ({inputErrorClassName, children}) => (
    <span className={twMerge('text-xs text-red-500 mt-1.5', inputErrorClassName)}>{children}</span>
);

export default InputError;
