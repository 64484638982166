import React, {useContext, useState} from "react";
import {Button, Col, Row} from "../components";
import {setFormFieldError} from "../helpers";
import styled, {ThemeContext} from "styled-components";
import {useForm} from "react-hook-form";
import {useBreakpoints} from "./useBreakpoints";
import toast from "react-hot-toast";

const EmptyMessageWrapper = styled.div`
    margin-top: 14px;
    text-align: center;
`;

const CreateNoteButtonWrapper = styled.div`
    text-align: center;
    margin-top: 24px;
    font-size: ${({theme}) => theme.fontSize.m.size};
`;

const NoteWrapper = styled.div`
    font-size: ${({breakpoints, theme}) => {
        if (breakpoints.md.viewport) return theme.fontSize.m.size;
        return theme.fontSize.s.size;
    }};
    line-height: ${({breakpoints, theme}) => {
        if (breakpoints.md.viewport) return theme.fontSize.m.line;
        return theme.fontSize.s.line;
    }};
`;

const EmptyMessage = ({formHandler, message, canEdit}) => {
    const theme = useContext(ThemeContext);

    return (
        <>
            <Row justify={'center'}>
                <Col xs={24} sm={24} lg={16}>
                    <EmptyMessageWrapper>{message}</EmptyMessageWrapper>
                    {canEdit && (
                        <CreateNoteButtonWrapper>
                            <Button
                                kind={'a'}
                                icon={'plus-circle'}
                                iconSize={25}
                                iconColor={theme.colors.blue.primary}
                                onClick={formHandler}
                            >
                                Добавить
                            </Button>
                        </CreateNoteButtonWrapper>
                    )}
                </Col>
            </Row>
        </>
    )
};

const UpdateForm = ({visible, modalHandler, submitHandler, initialValue, noteField}) => {
    const breakpoints = useBreakpoints();

    const {register, handleSubmit, setError, formState: {errors, isSubmitting}} = useForm({
        defaultValues: {
            [noteField]: initialValue
        }
    });

    const onSubmit = async values => {
        await submitHandler(
            values,
            proceedSubmit
        );
    };

    const proceedSubmit = (error, success) => {
        if (success) {
            toast.success('Примечание успешно добавлено или изменено');
            modalHandler();
        }

        if (error) {
            setFormFieldError(error.response.data, setError);
        }
    };

    return (
        <></>
        // <Modal.Dialog
        //     visible={visible}
        //     modalHandler={modalHandler}
        //     width={
        //         breakpoints.lg.viewport
        //             ? '972px'
        //             : '100%'
        //     }
        // >
        //     <Form onSubmit={handleSubmit(onSubmit)}>
        //         <Modal.Header>
        //             <Modal.Title>Примечание</Modal.Title>
        //             <Modal.PostTitle>
        //                 {initialValue
        //                     ? 'Исправьте примечание и сохраните изменения.'
        //                     : 'Напишите примечание и сохраните его.'
        //                 }
        //             </Modal.PostTitle>
        //         </Modal.Header>
        //         <Modal.Body>
        //             <Row pt={16}>
        //                 <Col xs={24} sm={24}>
        //                     <Textarea
        //                         id={noteField}
        //                         name={noteField}
        //                         error={errors[noteField]}
        //                         register={register}
        //                     />
        //                 </Col>
        //             </Row>
        //         </Modal.Body>
        //         <Modal.Footer>
        //             <Row>
        //                 <Col xs={24} sm={24}>
        //                     <Button
        //                         width={breakpoints.md.viewport ? '240px' : '100%'}
        //                         onClick={handleSubmit(onSubmit)}
        //                         disabled={isSubmitting}
        //                     >
        //                         Сохранить
        //                     </Button>
        //                 </Col>
        //             </Row>
        //         </Modal.Footer>
        //     </Form>
        // </Modal.Dialog>
    )
};

const useNote = () => {
    const [state, setState] = useState({
        showNoteForm: false
    });
    const breakpoints = useBreakpoints();

    const handleNoteForm = () => {
        setState({
            ...state,
            showNoteForm: !state.showNoteForm
        })
    };

    const Note = ({note, emptyMessage, submitHandler, noteField, canEdit = true}) => (
        <>
            {/*{note ? (*/}
            {/*    <Row>*/}
            {/*        <Col xs={24} sm={24}>*/}
            {/*            <NoteWrapper breakpoints={breakpoints}>*/}
            {/*                {note}*/}
            {/*            </NoteWrapper>*/}
            {/*        </Col>*/}
            {/*    </Row>*/}
            {/*) : (*/}
            {/*    <Row>*/}
            {/*        <Col xs={24} sm={24}>*/}
            {/*            <Empty message={*/}
            {/*                <EmptyMessage*/}
            {/*                    formHandler={handleNoteForm}*/}
            {/*                    message={emptyMessage}*/}
            {/*                    canEdit={canEdit}*/}
            {/*                />*/}
            {/*            }*/}
            {/*            />*/}
            {/*        </Col>*/}
            {/*    </Row>*/}
            {/*)}*/}
            {/*<UpdateForm*/}
            {/*    visible={state.showNoteForm}*/}
            {/*    modalHandler={handleNoteForm}*/}
            {/*    submitHandler={submitHandler}*/}
            {/*    initialValue={note}*/}
            {/*    noteField={noteField}*/}
            {/*/>*/}
        </>
    );

    return {
        handleNoteForm,
        Note
    }
};

export {useNote};
