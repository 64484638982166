import * as React from "react";
import {EllipsisHorizontalIcon} from "@heroicons/react/24/solid";
import {twMerge} from "tailwind-merge";

interface SectionTitle {
    as?: string;
    title: string;
    subTitle?: string | null;
    menuClickHandler?: () => void;
    containerClassname?: string | null;
    titleContainerClassname?: string;
    descriptionContainerClassname?: string;
    action?: React.ReactNode;
}

const SectionTitle: React.FC<SectionTitle> = (
    {
        as,
        title,
        subTitle,
        menuClickHandler,
        containerClassname,
        titleContainerClassname,
        descriptionContainerClassname,
        action,
    }
) => (
    <div
        className={twMerge(
            'grid auto-cols-auto auto-rows-auto items-center mb-6',
            containerClassname
        )}
    >
        <div
            className={twMerge(
                'col-start-1 row-start-1',
                titleContainerClassname
            )}
        >
            {React.createElement(
                as || 'h2',
                {
                    className: 'text-3xl sm:text-4xl text-slate-900 font-black tracking-tight'
                },
                title
            )}
        </div>
        {menuClickHandler && (
            <div className="flex justify-end col-start-2 row-start-1">
                <button
                    onClick={menuClickHandler}
                    className="bg-white focus:outline-none"
                >
                    <EllipsisHorizontalIcon className="h-8 w-8" aria-hidden="true"/>
                </button>
            </div>
        )}
        {action && action}
        {subTitle && (
            <div
                className={twMerge(
                    'col-start-1 row-start-2 font-medium text-slate-600 text-base md:text-xl mt-3',
                    descriptionContainerClassname
                )}
            >
                {subTitle}
            </div>
        )}
    </div>
);

export default SectionTitle;
