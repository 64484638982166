import * as React from "react";
import lazy from "@fatso83/retry-dynamic-import/react-lazy";
import {Dialog, DialogBackdrop, DialogPanel, Transition, TransitionChild} from "@headlessui/react";
import {twMerge} from "tailwind-merge";
import {useEditorStore} from "../../../../../store/useEditorStore";
import {useShallow} from "zustand/react/shallow";
import type {DealContactUpdaterType} from "../../../../../views/deals/Deal/sections/Contacts/Updater";
import type {DealContactCreatorType} from "../../../../../views/deals/Deal/sections/Contacts/Creator";

interface IUpdater {
    closeButtonRef: React.RefObject<HTMLButtonElement>;
}

type UpdaterType = React.FC<IUpdater>;

const PropertyUpdater = lazy(() => import('../../../../../views/Properties/Property/Property/components/Updater')) as unknown as UpdaterType;
const DealUpdater = lazy(() => import('../../../../../views/deals/Deal/components/Updater')) as unknown as UpdaterType;
const TaskUpdater = lazy(() => import('../../../../../views/tasks/Task/components/Updater')) as unknown as UpdaterType;
const LeadUpdater = lazy(() => import('../../../../../views/leads/Lead/components/Updater')) as unknown as UpdaterType;
const ContactUpdater = lazy(() => import('../../../../../views/contacts/Contact/sections/Name/Updater')) as unknown as UpdaterType;
const ContactPhoneCreator = lazy(() => import('../../../../../views/contacts/Contact/sections/Phones/Creator')) as unknown as UpdaterType;
const ContactEmailCreator = lazy(() => import('../../../../../views/contacts/Contact/sections/Emails/Creator')) as unknown as UpdaterType;
const DealContactUpdater = lazy(() => import('../../../../../views/deals/Deal/sections/Contacts/Updater')) as unknown as DealContactUpdaterType;
const DealContactCreator = lazy(() => import('../../../../../views/deals/Deal/sections/Contacts/Creator')) as unknown as DealContactCreatorType;

const EditorOverlay: React.FC = () => {
    const closeButtonRef = React.useRef<HTMLButtonElement>(null);

    const {
        type,
        containerClassName,
        closeEditor,
    } = useEditorStore(
        useShallow((state) => ({
            type: state.type,
            containerClassName: state.containerClassName,
            closeEditor: state.closeEditor,
        })),
    );

    return (
        <Transition show={!!type} as={React.Fragment}>
            <Dialog
                as="div"
                className="relative z-10"
                initialFocus={closeButtonRef}
                onClose={closeEditor}
                unmount={true}
            >
                <DialogBackdrop
                    transition={true}
                    className="fixed inset-0 bg-black/40 transition duration-300 ease-out backdrop-blur data-[data-leave]:ease-in data-[data-leave]:duration-200 data-[closed]:backdrop-blur-none data-[closed]:opacity-0"
                />
                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 md:p-0 md:items-center">
                        <TransitionChild
                            as={React.Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <DialogPanel
                                className={twMerge(
                                    'relative transform rounded-2xl bg-white text-left shadow-xl transition-all sm:m-8 sm:w-full sm:max-w-xl',
                                    containerClassName
                                )}
                            >
                                {type === 'property__update' && (
                                    <PropertyUpdater closeButtonRef={closeButtonRef}/>
                                )}
                                {type === 'deal__update' && (
                                    <DealUpdater closeButtonRef={closeButtonRef}/>
                                )}
                                {type === 'task__update' && (
                                    <TaskUpdater closeButtonRef={closeButtonRef}/>
                                )}
                                {type === 'lead__update' && (
                                    <LeadUpdater closeButtonRef={closeButtonRef}/>
                                )}
                                {type === 'contact__update' && (
                                    <ContactUpdater closeButtonRef={closeButtonRef}/>
                                )}
                                {type === 'contact__phone__create' && (
                                    <ContactPhoneCreator closeButtonRef={closeButtonRef}/>
                                )}
                                {type === 'contact__email__create' && (
                                    <ContactEmailCreator closeButtonRef={closeButtonRef}/>
                                )}
                                {type === 'property__contact__update' && (
                                    <DealContactUpdater
                                        type="properties"
                                        closeButtonRef={closeButtonRef}
                                    />
                                )}
                                {type === 'deal__contact__update' && (
                                    <DealContactUpdater
                                        type="deals"
                                        closeButtonRef={closeButtonRef}
                                    />
                                )}
                                {type === 'property__contact__create' && (
                                    <DealContactCreator
                                        type="properties"
                                        closeButtonRef={closeButtonRef}
                                    />
                                )}
                                {type === 'deal__contact__create' && (
                                    <DealContactCreator
                                        type="deals"
                                        closeButtonRef={closeButtonRef}
                                    />
                                )}
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default EditorOverlay;
