import * as React from "react";
import {useUpdateUser, useUser} from "@sputnikestate/graph";
import dayjs from "dayjs";
import {useCookies} from "react-cookie";
import {PresenceType, useIdleTimer} from "react-idle-timer";

interface IAccount extends ReturnType<typeof useUser> {
}

const accountContext = React.createContext<IAccount>({} as IAccount);

const AccountProvider = ({children}: { children: React.ReactNode }) => {
    const account = useAccountProvider();

    return (
        <accountContext.Provider value={account}>
            {children}
        </accountContext.Provider>
    );
};

const useAccount = () => {
    return React.useContext(accountContext);
};

const useAccountProvider = () => {
    const [isActive, setIsActive] = React.useState(true);
    const {user} = useUser();
    const [{sputnik_user_token}, setCookie, removeCookie] = useCookies(['sputnik_user_token']);

    const {mutate} = useUpdateUser();

    React.useEffect(
        () => {
            if (user.loggedIn) {
                setTokenCookie();
            } else {
                removeTokenCookie();
            }
        },
        [user, sputnik_user_token]
    );

    const onPresenceChange = (presence: PresenceType) => {
        if (presence.type === 'idle') setIsActive(false);
        if (presence.type === 'active') setIsActive(true);
    };

    const setUserIsActive = async () => {
        if (user.loggedIn && isActive) {
            await mutate({
                variables: {
                    payload: {
                        lastSeenAt: dayjs().toISOString(),
                    }
                }
            });
        }
    };

    useIdleTimer({onPresenceChange, timeout: 10000, crossTab: true});

    React.useEffect(
        () => {
            void setUserIsActive();
            const interval = setInterval(setUserIsActive, 30000);
            return () => {
                clearInterval(interval);
            };
        },
        [user.loggedIn, isActive]
    );

    const setTokenCookie = () => {
        if ('token' in user && user.token && sputnik_user_token !== user.token) {
            setCookie('sputnik_user_token', user.token, {
                path: '/',
                expires: dayjs().add(7, 'day').toDate(),
                secure: process.env.NODE_ENV === 'production',
                sameSite: 'lax',
            });
        }
    };

    const removeTokenCookie = () => {
        removeCookie('sputnik_user_token');
    };

    return {user};
};

export {AccountProvider, useAccount};
