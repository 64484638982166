import "intersection-observer";
import 'rc-slider/assets/index.css';
import './locale/i18n';
import './index.css';
import * as React from "react";
import {createRoot, hydrateRoot} from "react-dom/client";
import {App} from "./app";
import {BrowserRouter} from "react-router-dom";
import {HelmetProvider} from "react-helmet-async";
import * as Sentry from "@sentry/react";
import {CookiesProvider} from "react-cookie";
import {GraphProvider} from "@sputnikestate/graph";
import {client} from "./graph";
import {useSSR} from "react-i18next";
import {getLocaleState} from "./locale/helpers";
import ErrorFallback from "./components/composites/views/error";

declare global {
    interface Window {
        [key: string]: any;
    }
}

if (process.env.NODE_ENV === 'production') Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    release: process.env.REACT_APP_SENTRY_RELEASE
});

const Root: React.FC = () => {
    return (
        <Sentry.ErrorBoundary fallback={({error}) => <ErrorFallback error={error}/>}>
            <CookiesProvider>
                <GraphProvider client={client}>
                    <BrowserRouter>
                        <HelmetProvider>
                            <App/>
                        </HelmetProvider>
                    </BrowserRouter>
                </GraphProvider>
            </CookiesProvider>
        </Sentry.ErrorBoundary>
    )
};

const container = document.getElementById('root') as HTMLElement;

if (container.hasChildNodes()) {
    const {initialI18nStore, initialLanguage} = getLocaleState();

    const ServerRoot: React.FC = () => {
        useSSR(initialI18nStore, initialLanguage);
        return <Root/>;
    };

    hydrateRoot(container, <ServerRoot/>);
} else {
    const root = createRoot(container);
    root.render(<Root/>);
}
