import * as React from "react";
import {Route, Routes} from "react-router-dom";
import {useTranslation} from "react-i18next";
import lazy from '@fatso83/retry-dynamic-import/react-lazy';

const EstatesPage = lazy(() => import('../views/estates/Estates'));
const EstatePage = lazy(() => import('../views/estates/estate/Estate'));
const EstatePropertiesPage = lazy(() => import('../views/estates/estate/Properties'));

const EstatesStack: React.FC = () => {
    const {t} = useTranslation('urls');

    return (
        <Routes>
            <Route
                path='/'
                element={<EstatesPage/>}
            />
            <Route
                path={t('villages.village.client')}
                element={<EstatePage/>}
            />
            <Route
                path={t('villages.village.properties.client')}
                element={<EstatePropertiesPage/>}
            />
        </Routes>
    );
};

export {EstatesStack};
