import * as React from "react";
import lazy from "@fatso83/retry-dynamic-import/react-lazy";

interface IErrorFallback {
    error: any;
}

const NotFoundPage = lazy(() => import('../../../../views/NotFound'));

const ErrorFallback: React.FC<IErrorFallback> = ({error}) => {
    for (const i of error.graphQLErrors || []) {
        if (i.extensions.code === 'INTERNAL_SERVER_ERROR') {
            const response = i.extensions.response as any;
            if (response?.status === 404) {
                return <NotFoundPage/>;
            }
        }
    }

    return null;
};

export default ErrorFallback;
