import * as React from "react";
import {IIconSVGProps} from "./types";

const CallIcon: React.FC<IIconSVGProps> = ({color, className, ref}) => (
    <svg ref={ref} className={className} viewBox="0 0 560 560.177281" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.344289863,109.400263 C-11.0236838,287.437059 261.705202,548.795659 435.945811,560.177281 C462.458976,560.177281 485.185207,556.391041 504.126818,533.664115 C515.494792,518.509902 530.639983,507.15095 545.79466,491.996274 C564.735113,473.05582 564.735113,450.328432 545.79466,431.389135 C523.067966,404.87597 496.555263,378.353551 470.032844,355.62732 C451.092391,336.686866 432.151937,336.686866 413.21264,355.62732 C398.058426,370.781533 379.118666,385.927419 363.973243,401.079782 C360.187003,408.652262 352.605269,408.652262 345.032789,404.866022 C273.067161,363.19818 185.937604,291.232553 155.637504,211.683803 C151.851264,204.111323 151.851264,200.31583 159.423745,192.74335 C174.577958,181.375376 189.723844,162.44325 204.876207,147.290887 C223.816661,128.350433 223.816661,105.623045 204.876207,86.6837482 C178.363042,63.9570543 155.63681,37.4443516 129.114392,14.7181204 C110.173938,-4.22233345 87.44655,-4.22233345 68.5072529,10.9318803 C53.3530392,29.8723341 38.2071535,45.0258538 23.0547904,60.1712768 C7.90057668,71.5392505 0.328096497,90.4713763 0.328096497,109.410673 L0.344289863,109.400263 Z"
            fill={color}
        />
    </svg>
);

export default CallIcon;
