import i18n from "./i18n";
import LanguageDetector from "i18next-browser-languagedetector";
import {isArray} from "lodash";
import {Locale, supportedLanguages} from "./constants";
import {Resource, TFunction} from "i18next";
import {AddressFieldsFragment, PropertyDetailsFieldsFragment, PropertyKind} from "@sputnikestate/graph";

declare global {
    interface Window {
        initialI18nStore: Resource;
        initialLanguage: string;
    }
}

interface IGetPrice {
    t: TFunction;
    price: number;
    currency: string;
    type?: string;
    monthSuffix?: string;
}

type TGetPrice = (props: IGetPrice) => string;

interface IGetArea {
    t: TFunction;
    area: number;
    unit: string;
}

type TGetArea = (props: IGetArea) => string;

export const getLocaleState = () => {
    const initialI18nStore = window.initialI18nStore;
    const initialLanguage = window.initialLanguage;

    const script = document.getElementById('locale');
    if (script) script.remove();

    return {initialI18nStore, initialLanguage};
};

export const getPrice: TGetPrice = ({t, price, currency, type, monthSuffix = 'perMonth'}) => {
    let value = t('number', {ns: 'common', value: price, minimumFractionDigits: 0});
    if (currency === 'USD') {
        if (value.startsWith('-')) value = '-' + t('price.USD.value', {
            ns: 'common',
            value: value.slice(1),
            count: price
        });
        else value = t('price.USD.value', {ns: 'common', value, count: price});
    }
    if (currency === 'EUR') {
        if (value.startsWith('-')) value = '-' + t('price.EUR.value', {
            ns: 'common',
            value: value.slice(1),
            count: price
        });
        else value = t('price.EUR.value', {ns: 'common', value, count: price});
    }
    if (currency === 'RUB') {
        value = t('price.RUB.value', {ns: 'common', value, count: price});
    }
    if (currency === 'AED') {
        value = t('price.AED.value', {ns: 'common', value, count: price});
    }
    if (type === 'lease') {
        return t(monthSuffix, {price: value});
    }
    return value;
};

export const getArea: TGetArea = ({t, area, unit}) => {
    return t(`area.${unit}.value`, {ns: 'general', count: area});
};

export const getLang = () => {
    if (i18n.language) return i18n.language;

    const detector = new LanguageDetector();
    let lang = detector.detect();

    if (isArray(lang)) {
        if (lang.length > 0) lang = lang[0];
        else return Locale.EN;
    }

    if (lang) {
        if (supportedLanguages.includes(lang)) return lang;
    }

    return Locale.EN;
};

export const getAddressString = ({address}: { address?: AddressFieldsFragment | null }) => {
    if (address) {
        return `${address.name}, ${address.description}`;
    }
    return null;
};

export const getAreaValue = ({property, t}: { property: PropertyDetailsFieldsFragment; t: TFunction }) => {
    let value;

    if (property.kind === PropertyKind.Land && 'landArea' in property.details) {
        value = property.details.landArea;
    }

    if (!value && 'propertyArea' in property.details) {
        value = property.details.propertyArea;
    }

    return t('number', {value});
};

export const getAreaUnitValue = ({property, t}: { property: PropertyDetailsFieldsFragment; t: TFunction }) => {
    let count = 0;
    let unit = 'sq_ft';

    if (property.kind === PropertyKind.Land && 'landArea' in property.details) {
        count = property.details.landArea || 0;
        unit = property.details.landAreaUnit;
    }

    if (!count && 'propertyArea' in property.details) {
        count = property.details.propertyArea || 0;
        unit = property.details.propertyAreaUnit;
    }

    return t(`area.${unit}.name`, {ns: 'general', count});
};
