import React from "react";
import styled from "styled-components";
import {useBreakpoints} from "../../hooks";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${({breakpoints, type}) => {
    if (type === 'column') return 'repeat(1, 1fr)';
    if (type === 'row') return 'min-content auto';
    if (breakpoints.md.viewport) return 'repeat(1, 1fr)';
    return 'repeat(2, 1fr)';
}};
  grid-row-gap: ${({breakpoints, type}) => {
    if (type === 'column') return '4px';
    if (type === 'row') return 0;
    if (breakpoints.md.viewport) return '4px';
    return 0;
}};
  text-align: ${({textAlign}) => textAlign ? textAlign : 'left'};
  margin-top: ${({mt}) => mt ? `${mt}px` : 0};
  margin-bottom: ${({mb}) => mb ? `${mb}px` : 0};
`;

const Name = styled.div`
  margin-right: ${({type}) => type === 'row' ? '10px' : 0};
  font-weight: ${({type}) => type === 'row' ? 600 : 400};
  font-size: ${({breakpoints, type, valueSize, theme}) => {
    if (type === 'row') {
        if (valueSize) return theme.fontSize[valueSize].size;
        return theme.fontSize.s.size;
    }
    if (type === 'column') return theme.fontSize.xs.size;
    if (breakpoints.md.viewport) return theme.fontSize.xs.size;
    return theme.fontSize.s.size;
}};
  color: ${({breakpoints, type, color, theme}) => {
    if (color) return color;
    if (type === 'row') return theme.textColor;
    if (type === 'column') return theme.textColor;
    if (breakpoints.md.viewport) return theme.textColor;
    return theme.colors.battleshipGrey[12];
}};
  white-space: ${({nameWrap}) => nameWrap ? 'normal' : 'nowrap'};
`;

const Value = styled.div`
  font-weight: ${({valueWeight}) => (valueWeight) ? valueWeight : 400};
  font-size: ${({breakpoints, valueSize, theme}) => {
    if (valueSize) return theme.fontSize[valueSize].size;
    if (breakpoints.md.viewport) return theme.fontSize.m.size;
    return theme.fontSize.s.size;
}};
`;

const Parameter = ({
                       name,
                       nameColor,
                       nameWrap = true,
                       value,
                       valueWeight,
                       valueSize,
                       textAlign,
                       type,
                       mt,
                       mb,
                       children
                   }) => {
    const breakpoints = useBreakpoints();

    return (
        <Wrapper
            breakpoints={breakpoints}
            type={type}
            textAlign={textAlign}
            mt={mt}
            mb={mb}
        >
            {name && (
                <Name
                    breakpoints={breakpoints}
                    nameWrap={nameWrap}
                    type={type}
                    valueSize={valueSize}
                    color={nameColor}
                >
                    {name}
                </Name>
            )}
            <Value
                breakpoints={breakpoints}
                valueWeight={valueWeight}
                valueSize={valueSize}
            >
                {value}
            </Value>
            {children}
        </Wrapper>
    )
};

export {Parameter};
