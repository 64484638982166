import * as React from "react";

interface IForm {
    children: React.ReactNode;
    onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
}

const Form: React.FC<IForm> = ({children, ...props}) => {
    return (
        <form {...props}>
            {children}
        </form>
    )
};

export {Form};
