import React from "react";
import {useBreakpoints} from "../../hooks";
import {useInView} from "react-intersection-observer";
import {Col, Divider, Empty, Parameter, Row, Tag} from "../index";
import dayjs from "dayjs";
import styled from "styled-components";
import Element from "../composites/scroll/Element";
import SectionTitle from "../SectionTitle";

const EventWrapper = styled.div`
    font-size: ${({theme}) => theme.fontSize.s.size};
    display: grid;
    grid-template-columns: ${({breakpoints}) => breakpoints.md.viewport ? 'repeat(3, 1fr)' : 'repeat(1, 1fr)'};

    &:not(:last-child) {
        margin-bottom: ${({breakpoints}) => breakpoints.md.viewport ? '5px' : '20px'};
    }
`;

const NoEventsWrapper = styled.div`
    padding-top: 5px;
    padding-bottom: 10px;
`;

const TagsWrapper = styled.div`
    display: flex;
    margin-top: 10px;

    & span {
        margin-right: 10px;
    }
`;

const EmailParametersWrapper = styled.div`
    margin-top: 25px;
    display: grid;
    grid-template-columns: ${({breakpoints}) => breakpoints.md.viewport ? 'repeat(3, 1fr)' : 'repeat(1, 1fr)'};
    grid-row-gap: ${({breakpoints}) => {
        if (breakpoints.md.viewport) return '18px';
        return '14px';
    }};
`;

const EmailWrapper = styled.div`
    border-style: solid;
    border-width: 1px;
    border-color: ${({theme}) => theme.colors.gray[3]};
    border-radius: 4px;
    padding: 15px 20px 14px;

    &:not(:last-child) {
        margin-bottom: 16px;
    }
`;

const EmailEvent = ({event}) => {
    const breakpoints = useBreakpoints();

    return (
        <EventWrapper breakpoints={breakpoints}>
            <div>{dayjs(event.received_at).format('D MMMM YYYY в HH:mm')}</div>
            <div>{event.event_display.title}</div>
        </EventWrapper>
    )
};

const NoEvents = () => {
    return (
        <NoEventsWrapper>
            <Empty message={'Нет событий. Письмо даже не открыли.'}/>
        </NoEventsWrapper>
    )
};

const EmailEvents = ({email}) => {
    return (
        <div>
            {email.events.length > 0 ? (
                email.events.map((event, i) => <EmailEvent key={i}
                                                           event={event}
                />)
            ) : <NoEvents/>}
        </div>
    )
};

const EmailSubject = ({email}) => {
    return (
        <div>
            <Parameter
                type={'column'}
                name={'Тема'}
                value={email.subject}
                valueWeight={'700'}
                valueSize={'l'}
            />
            <TagsWrapper>
                <Tag value={email.type_display.title}/>
                <Tag type={email.state_display.style}
                     value={email.state_display.title}
                />
            </TagsWrapper>
        </div>
    )
};

const EmailParameters = ({email}) => {
    const breakpoints = useBreakpoints();

    return (
        <div>
            <EmailParametersWrapper breakpoints={breakpoints}>
                <Parameter
                    type={'column'}
                    name={'От'}
                    value={
                        email.from_employee
                            ? email.from_employee.short_name
                            : email.from_contact
                                ? email.from_contact.short_name
                                : '—'
                    }
                    valueSize={'s'}
                    valueWeight={'700'}
                >
                    {email.from_employee_email_address || email.from_contact_email_address && (
                        <Parameter
                            type={'row'}
                            valueSize={'s'}
                            value={
                                email.from_employee_email_address
                                    ? email.from_employee_email_address
                                    : email.from_contact_email_address
                            }
                        />
                    )}
                </Parameter>
                <Parameter
                    type={'column'}
                    name={'Кому'}
                    value={
                        email.to_contact
                            ? email.to_contact.short_name
                            : email.to_employee
                                ? email.to_employee.short_name
                                : '—'
                    }
                    valueSize={'s'}
                    valueWeight={'700'}
                >
                    {email.to_contact_email_address || email.to_employee_email_address && (
                        <Parameter
                            type={'row'}
                            valueSize={'s'}
                            value={
                                email.to_contact_email_address
                                    ? email.to_contact_email_address
                                    : email.to_employee_email_address
                            }
                        />
                    )}
                </Parameter>
                <Parameter
                    type={'column'}
                    name={'Когда'}
                    value={dayjs(email.send_at).format('D MMMM YYYY в HH:mm')}
                    valueSize={'s'}
                />
            </EmailParametersWrapper>
        </div>
    )
};

const Email = ({email}) => {
    return (
        <EmailWrapper>
            <EmailSubject email={email}/>
            <EmailParameters email={email}/>
            <Divider top={24} bottom={25}/>
            <EmailEvents email={email}/>
        </EmailWrapper>
    )
};

const Emails = ({title, contact}) => {
    const breakpoints = useBreakpoints();
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0,
    });

    return (
        <Element name={'emails'}>
            <Row
                pt={breakpoints.md.viewport ? 56 : 21}
                pb={
                    data && data.length > 0
                        ? breakpoints.md.viewport
                            ? 50
                            : 35
                        : breakpoints.md.viewport
                            ? 44
                            : 28
                }
            >
                <Col xs={24} sm={24}>
                    <div ref={ref}>
                        <SectionTitle
                            title={title}
                        />
                        <Row>
                            <Col xs={24} sm={24}>
                                {data && data.length > 0 ? (
                                    data.map((email, i) => <Email key={i}
                                                                  email={email}
                                    />)
                                ) : data && data.length === 0 ? (
                                    <Empty message={'Нет электронных писем.'}/>
                                ) : <div>Загрузка</div>}
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Element>
    )
};

export {Emails};
