import * as React from "react";
import lazy from '@fatso83/retry-dynamic-import/react-lazy';
import {Route, Routes} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {PropertiesPageType} from "../views/Properties/Properties";
import DynamicRoute from "./DynamicRoute";

const DevelopmentsPage = lazy(() => import('../views/developments/Developments'));
const DevelopmentPage = lazy(() => import('../views/developments/development/Development'));
const DevelopmentPropertiesPage = lazy(() => import('../views/developments/development/Properties'));
const PropertiesPage = lazy(() => import('../views/Properties/Properties')) as unknown as PropertiesPageType;

const DevelopmentsStack: React.FC = () => {
    const {t} = useTranslation('urls');

    return (
        <Routes>
            <Route
                path={'/'}
                element={<DevelopmentsPage/>}
            />
            <Route
                path={t('developments.development.client')}
                element={<DevelopmentPage/>}
            />
            <Route
                path={t('developments.development.properties.client')}
                element={<DevelopmentPropertiesPage/>}
            />
            <Route
                path={t('developments.properties.client')}
                element={
                    <PropertiesPage
                        initial={{
                            details__offer_status: 'primary_offer',
                        }}
                        header={{
                            title: 'developments.core.properties.main.header.title',
                            description: 'developments.core.properties.main.header.description',
                        }}
                        metaPath={'developments.properties'}
                        urlPath={'developments.properties.absolute'}
                        objective={'developments'}
                    />
                }
            />
            <Route
                path='*'
                element={<DynamicRoute/>}
            />
        </Routes>
    );
};

export {DevelopmentsStack};
