import * as React from "react";
import {Dialog, DialogBackdrop, DialogPanel, Transition, TransitionChild} from "@headlessui/react";
import {XMarkIcon} from "@heroicons/react/24/outline";
import LoginForm from "./components/Form";
import {useLoginStore} from "@sputnikestate/store";

const Login: React.FC = () => {
    const closeButtonRef = React.useRef(null);

    const isVisible = useLoginStore((state) => state.isVisible);
    const close = useLoginStore((state) => state.close);

    return (
        <Transition show={isVisible} as={React.Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={closeButtonRef} onClose={close}>
                <DialogBackdrop
                    transition={true}
                    className="fixed inset-0 bg-black/40 transition duration-300 ease-out backdrop-blur data-[data-leave]:ease-in data-[data-leave]:duration-200 data-[closed]:backdrop-blur-none data-[closed]:opacity-0"
                />
                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end lg:items-center justify-center p-2 xl:p-4">
                        <TransitionChild
                            as={React.Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 lg:translate-y-0 lg:scale-95"
                            enterTo="opacity-100 translate-y-0 lg:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 lg:scale-100"
                            leaveTo="opacity-0 translate-y-4 lg:translate-y-0 lg:scale-95"
                        >
                            <DialogPanel
                                className="relative transform rounded-2xl bg-white text-left shadow-xl transition-all w-full lg:w-9/12 xl:w-3/6"
                            >
                                <div className="relative flex flex-col pt-4 xl:pt-6 pb-4 lg:pb-28 2xl:pb-32 h-full">
                                    <div className="flex justify-end items-start px-4 xl:px-6 pt-0.5 z-10">
                                        <button
                                            type="button"
                                            className="flex items-center justify-center h-10 w-10 rounded-full border border-transparent bg-cultured-100 hover:bg-cultured-150 focus:outline-none"
                                            onClick={close}
                                            ref={closeButtonRef}
                                        >
                                            <XMarkIcon className="h-6 w-6 text-blue-500"/>
                                        </button>
                                    </div>
                                    <div className="flex flex-col justify-center mt-8 lg:mt-12 2xl:mt-14 h-full">
                                        <LoginForm/>
                                    </div>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export default Login;
