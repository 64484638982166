import * as React from "react";
import lazy from '@fatso83/retry-dynamic-import/react-lazy';
import {Route, Routes} from "react-router-dom";
import {StaffProtected} from "./Protected";
import {IPropertyPage} from "../views/Properties/Property/Property";
import {PropertyObjectiveType} from "../types";

const PropertyPage = lazy(() => import('../views/Properties/Property/Property')) as unknown as React.FC<IPropertyPage>;
const PropertyImagesCreatePage = lazy(() => import('../views/Properties/Property/Images/Create'));
const PropertyImagesEditPage = lazy(() => import('../views/Properties/Property/Images/Edit'));

interface IPropertyStack {
    objective: PropertyObjectiveType,
}

const PropertyStack: React.FC<IPropertyStack> = ({objective = 'plain'}) => (
    <Routes>
        <Route
            path='/'
            element={
                <PropertyPage objective={objective}/>
            }
        />
        <Route element={<StaffProtected/>}>
            <Route
                path={'images'}
                element={
                    <PropertyImagesEditPage objective={objective}/>
                }
            />
            <Route
                path={'images/add'}
                element={
                    <PropertyImagesCreatePage objective={objective}/>
                }
            />
        </Route>
    </Routes>
);

export {PropertyStack};
